const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/supply-chain",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "../views/dashboard/dashboard.vue"
      ), // webpack的魔法注释，将拆分出的js命名为Dashboard
  },
  {
    path: "/pushManage",
    name: "PushManage",
    component: () =>
      import(
        /* webpackChunkName: "PushManage" */ "../views/pushManage/PushManage.vue"
      ), // webpack的魔法注释，将拆分出的js命名为PushManage
  },
  /* add-router-误删-自动化增加路由使用 */

  {
    path: "/data-center*",
    name: "data-center",
    component: () =>
      import(
        /* webpackChunkName: "dataCenter" */ "../views/child/dataCenter.vue"
      ),
  },
  {
    path: "/supply-chain*",
    name: "supply-chain",
    component: () =>
      import(
        /* webpackChunkName: "supplyChain" */ "../views/child/supplyChain.vue"
      ),
  },
  {
    path: "/channel-line*",
    name: "channel-line",
    component: () =>
      import(
        /* webpackChunkName: "channelLine" */ "../views/child/channelLine.vue"
      ),
  },
  {
    path: "/trade-center*",
    name: "trade-center",
    component: () =>
      import(
        /* webpackChunkName: "tradeCenter" */ "../views/child/tradeCenter.vue"
      ),
  },
  {
    path: "/marketing-center*",
    name: "marketing-center",
    component: () =>
      import(
        /* webpackChunkName: "marketingCenter" */ "../views/child/marketingCenter.vue"
      ),
  },
  {
    path: "/business-online",
    name: "business-online",
    component: () =>
      import(
        /* webpackChunkName: "businessOnlineCenter" */ "../views/child/businessOnline.vue"
      ),
  },
  {
    path: "/customer-service",
    name: "customer-service",
    component: () =>
      import(
        /* webpackChunkName: "customerServieCenter" */ "../views/child/customerService.vue"
      ),
  },
];

export default routes;
