<template>
  <div id="sidebar-app">
    <div class="nav c-flex-x-between">
      <div class="logo-part c-flex-x-center">
        <div class="system-name c-flex-x-center">
          <img class="logo" src="@/assets/img/nav/logo.png" />
          桃花坡
        </div>
        <div class="c-flex-x-start module-group">
          <!-- 各个子应用模块入口 -->
          <div
            v-for="(item, index) in appList"
            :key="index"
            class="c-flex-x-center module"
            :class="[{ active: currRouteName === item.defaultRouterName }]"
            @click="jump(item.path)"
            @mouseenter="mouseEnter"
          >
            {{ item.title || "" }}
          </div>
        </div>
      </div>

      <div class="setting-part c-flex-x-end">
        <!-- 新消息 -->
        <div
          v-hasBtn="'thpCenterInfo'"
          class="c-flex-x-end newinfo"
          @click="
            jumpMicroApp(
              '/marketing-center#/chouCheck',
              'marketing-center',
              '/chouCheck'
            )
          "
          v-if="
            msgInfo.openWineWaitAuditCount ||
            msgInfo.banquetFreeWaitAuditCount ||
            msgInfo.dianShangWaitAuditCount
          "
        >
          <i class="el-icon-message-solid cfff"></i>
          <span>您有新的宴席审核</span>
        </div>
        <!-- 部门列表 -->
        <div class="dept-info" v-if="deptOptions.length">
          <el-dropdown trigger="hover" @command="handleCommand">
            <span class="el-dropdown-link userinfo-inner">
              {{ deptOptions[0] ? deptOptions[0].name : "" }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in deptOptions"
                :key="index"
                :command="item.id"
                >{{ item.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <!-- 个人中心 -->
        <div class="userInfo">
          <el-dropdown trigger="hover">
            <span class="el-dropdown-link userinfo-inner"
              ><i class="el-icon-user-solid"></i>&nbsp;&nbsp;{{
                userInfo.userName || ""
              }}</span
            >
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="msgCenter"
                >消息中心</el-dropdown-item
              >
              <el-dropdown-item @click.native="logoutClick"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <!-- <div v-show="show" class="nav-detail" @mouseleave="show = false">
      <div class="title c-flex-x-start">产品中心</div>

      <div class="detail-menu">
        <div class="menu-1 c-flex-x-y-start">
          <div class="btn menu-title">菜单一</div>
          <div class="btn" @click="jumpMicroApp('/data-center', 'data-center', '/')">
            home
          </div>
          <div
            class="btn"
            @click="jumpMicroApp('/data-center#/list', 'data-center', '/list')"
          >
            list
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { micro } from "@/utils/microApp/micro";
import { mapMutations, mapActions, mapState } from "vuex"; // vuex

// import { moduleMapJson, getAppList } from "@/utils/business/auth/auth";
import { moduleMapJson, getAppList } from "@/utils/business/auth/authIdass";

import { goLoginIdaas } from "@/api/callApi";
import { removeCookie, getStorage } from "@/utils";
import { logout } from "@/utils/business/login/login";
import { queryWaitAuditCountApi } from "@/utils/business/login/service/loginService";

// import mergeOld from '@/utils/business/login/common/mergeOld'

import tLog from "@/utils/tlog/tlog";

export default {
  name: "App",
  data() {
    return {
      show: false, // 展示导航中的二级菜单
      // 部门
      curDept: localStorage.getItem("roleName"),
      // 部门下拉列表数据
      // deptOptions: [],
      userName: JSON.parse(localStorage.getItem("user")),

      appList: getAppList(moduleMapJson()),

      userInfo: {},
      bizRoleCodes: [],
      msgInfo: { banquetFreeWaitAuditCount: "", openWineWaitAuditCount: "" },
    };
  },
  computed: {
    ...mapState(["currRouteName", "deptOptions"]),
  },
  created() {},
  mounted() {
    this.$nextTick(async () => {
      this.curDept = this.$store.state.deptName;

      const userInfo = getStorage("thp_userInfo");
      this.userInfo = userInfo;
      // const id = userInfo.id;
      // mergeOld.getPartId(id);

      // 获取部门列表
      this.setDeptList();
      // 消息发送
      this.queryMessageApi();
      this.setIntervalFn();
    });
  },
  methods: {
    ...mapMutations(["SET_DEPTOPTIONS"]),
    setDeptList() {
      const deptOptions = getStorage("deptList");
      if (deptOptions) {
        this.SET_DEPTOPTIONS(deptOptions);
      }
    },
    // 获取消息
    queryMessageApi() {
      return new Promise((resolve) => {
        queryWaitAuditCountApi().then((res) => {
          this.msgInfo = res.data;
          resolve(res);
        });
      });
    },
    setIntervalFn() {
      setInterval(() => {
        this.queryMessageApi();
      }, 60000);
    },
    jump(path) {
      console.log(path);
      if (path == "/data-center") {
        window.open("https://das.base.shuju.aliyun.com/product/v3.htm");
      } else {
        this.$router.push(path);
      }

      tLog.mytLog("event_cate", "tfe_tracking", { aa: 1, bb: 2 });
    },
    jumpMicroApp(path, appName, childPath = "/") {
      // 主应用跳转
      this.$router.push(path);
      // let childPath = path.replace(/^\/data-[^/]+/, "");
      // !childPath && (childPath = "/"); // 防止地址为空
      // console.log("appName,跳", appName, childPath);
      micro.registerData(appName, { path: childPath, data: "12333" });
      this.show = false;
    },
    mouseEnter(path) {
      this.show = false;
      if (path === "/data-center") {
        this.show = true;
      }
    },

    /**
     *  部门切换回调事件
     */
    handleCommand(command) {
      const nowRole = localStorage.getItem("deptId");
      const deptLevel = this.$store.state.deptLevel;
      const busnCenter = JSON.parse(localStorage.getItem("busnCenter"));
      this.curUser = JSON.parse(localStorage.getItem("account")) || "";

      if (nowRole !== command) {
        this.$store.commit("SET_DEPTID", command);
        localStorage.setItem("deptId", command);
        // this.menuList = [];
        this.deptOptions.forEach((item, index) => {
          if (item.id === command) {
            this.curDept = item.name;
            // 保存是否是部门负责人
            localStorage.setItem("isOwner", JSON.stringify(item.isOwner));
            // 判断是否是超级管理员
            if (
              JSON.parse(localStorage.getItem("roleNameList")).includes(
                "主管"
              ) &&
              (this.curDept === "销售运营组" ||
                this.curDept === "业务中台" ||
                this.curDept === "潭酒事业部") &&
              localStorage.getItem("userStation") === "销售运营经理"
            ) {
              localStorage.setItem("deptLevel", "admin");
              this.$store.commit("SET_DEPTLEVEL", "admin");
            } else {
              localStorage.setItem("deptLevel", item.level);
              this.$store.commit("SET_DEPTLEVEL", item.level);
            }

            this.$store.commit("SET_DEPTNAME", item.name);
            localStorage.setItem("deptName", item.name);
          }
        });
      }
    },

    // 退出登录
    logoutClick() {
      this.$confirm("确定退出吗?", "提示", {}).then(async () => {
        // 退登idaas方法
        await logout(() => {
          // 清除localStorage数据
          this.removeStorage();
          // 清除cookie数据
          this.removeCookies();

          // 重置vuex数据
          this.resetVuex();
        });

        // 跳转登录页
        // this.$router.push("/login");
        // goLoginIdaas();
      });
    },
    msgCenter() {
      this.$router.push({
        name: `PushManage`,
      });
    },
    removeStorage() {
      const storageList = [
        `user`,
        `token`,
        `account`,
        `id`,
        `deptLevel`,
        `deptList`,
        `roleList`,
        `deptId`,
        `role`,
        `deptName`,
        `isSecretary`,
        `busnCenter`,
        `roleName`,
        `tuserPartId`,
        `userStation`,
        `roleNameList`,
        `isOwner`,
        `isMANAGER`,
        `businessArea`,
        `isDirector`,
        `thp_userInfo`,
      ];

      storageList.forEach((item) => {
        localStorage.removeItem(item);
      });
    },
    removeCookies() {
      removeCookie("thp_idassToken");
    },
    resetVuex() {
      const storeList = [
        {
          mutationsKey: `SET_DEPTLIST`,
          value: [],
        },
        {
          mutationsKey: `SET_DEPTID`,
          value: ``,
        },
        {
          mutationsKey: `SET_DEPTNAME`,
          value: ``,
        },
        {
          mutationsKey: `SET_DEPTLEVEL`,
          value: ``,
        },
        {
          mutationsKey: `SET_ISMANAGER`,
          value: ``,
        },
      ];
      storeList.forEach((item) => {
        this.$store.commit(item.mutationsKey, item.value);
      });
    },
  },
};
</script>

<style lang="scss">
#sidebar-app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f0f0f0;
  margin: 0 auto;
  position: relative;

  .nav {
    width: 100%;
    height: 60px;
    background-color: #55207e;
    .logo-part {
      .system-name {
        color: #fff;
        width: 200px;
        height: 60px;
        font-size: 20px;
        font-weight: 600;
        line-height: 60px;
        .logo {
          height: 50px;
          // margin-right: 10px;
        }
      }
      .module-group {
        height: 60px;
        .module {
          height: 100%;
          padding: 0 20px;
          color: #fff;
          font-weight: 600;
          font-size: 16px;
          cursor: pointer;

          &.active {
            background: #6722ab;
          }
        }
      }
    }
    .newinfo {
      background: #ffffff40;
      height: 36px;
      padding: 7px 12px;
      color: #fff;
      font-size: 14px;
      box-sizing: border-box;
      .cfff {
        margin-right: 4px;
      }
    }
    .setting-part {
      padding-right: 50px;

      .userInfo,
      .dept-info {
        // width: 160px;
        height: 60px;
        line-height: 60px;
        font-size: 18px !important;
        .el-dropdown {
          min-width: 80px;
        }
        .userinfo-inner {
          cursor: pointer;
          color: #fff;
        }
      }
    }
  }

  .nav-detail {
    min-height: 100px;
    width: 1000px;
    left: 50%;
    transform: translate(-50%, 0);
    background: #fff;
    border: 1px solid #ccc;
    position: absolute;
    top: 60px;
    z-index: 999;
    .title {
      box-sizing: border-box;
      width: 100%;
      height: 30px;
      background-color: rgba(247, 239, 253, 1);
      font-weight: 500;
      padding: 0 10px;
    }
    .detail-menu {
      .menu-1 {
        width: 200px;
        .btn {
          width: 160px;
          padding: 10px 0;
          margin: 0 auto;
          cursor: pointer;

          &.menu-title {
            border-bottom: 1px dashed #000;
          }
        }
      }
    }
  }

  h4 {
    font-weight: revert;
  }

  .el-menu-item {
    font-size: 16px;
  }

  .el-menu {
    width: 200px;
    border-right: none;
  }

  .submenu-text {
    font-size: 16px;
    user-select: none;
  }

  .menu-item-text {
    font-size: 14px;
    user-select: none;
  }
}
</style>
