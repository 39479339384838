import { getCookie } from "@/utils";
import { staticData } from "@/config/base.config";

// api接口
import { loginApi, logOutApi,getUserGuid } from "./service/loginService";

import { setCookie, setStorage } from "@/utils";
import { urlToJson, toQueryString } from "@/utils/common/url_query";
import store from "../../../store";
import { goLoginIdaas, getLoginUrl,goLogOutThp } from "@/api/callApi";
import tLog from '@/utils/tlog/tlog'
import { appId, product } from "@/config/base.config";

import mergeOld from './common/mergeOld'

const TOKENKEY = `thp_idassToken`;

async function checkLogin() {
  return new Promise(async (resolve, reject) => {
    const paramJson = urlToJson(window.location.search).paramJson;
    const code = paramJson.code || "";
    const state = paramJson.state || "";

    // console.log(code)
    // console.log(state)
    // return

    if (code) {
      if (state) {
        goRedirectUrl(code, state);
        return;
      }

      // 通过code，获取accessToken
      try {
        const res = await getAccessToken(code);
        const accessToken =
          res.data && res.data.accessToken ? res.data.accessToken : "";
        
        // 老tc用户数据及token
        const tcInfo = res.data && res.data.tcInfo ? res.data.tcInfo : {};

        // 设置accessToken，到cookie中；1天过期
        setAccessToken(accessToken);
        console.log('登录res', tcInfo)
        setStorage("id", tcInfo.id) // 存储用户id，老接口用
        setStorage("user", JSON.stringify(tcInfo.userName)) // 存储用户id，老接口用
        setStorage("account", JSON.stringify(tcInfo.userAccount)) // 存储用户id，老接口用
        // 存储用户基本信息
        store.commit("SET_USERINFO", tcInfo);
        setStorage("token", JSON.stringify(tcInfo.token));
        setStorage("thp_userInfo", tcInfo); // 存储用户信息
        
        const userInfo = await getUserGuid(accessToken);
        let guid = userInfo?.data?.guid;
        if(guid){
          localStorage.setItem('guid',guid);
          tLog.register(product, guid);
        }

        // 清除url的pushstate，取消url上的code和state数据
        clearUrlToken();
        // 登录打点
        // 线上环境，注册火山引擎
        // tLog.init(appId);
        // tLog.register(product);
        // tLog.myLoginLog(tcInfo.id)
        // console.log('登录打点id==',tcInfo.id)

        // 兼容老逻辑
        mergeOld.getPartId(tcInfo.id); 


      } catch (err) {
        // 清除url的pushstate，取消url上的code和state数据
        clearUrlToken();

        setTimeout(() => {
          logout()
        }, 3000)
      }
    } else {
      checkTokenIdaas();
    }

    resolve();
  });
}
// 重定向到跳转登录页面前的地址
function goRedirectUrl(code, state) {
  const originAddress = decodeURIComponent(state);
  const urlArr = originAddress.split("#");

  const redirectURl = urlArr[0] || "";
  const hash = urlArr[1] ? `#${urlArr[1]}` : "";

  // 回调地址
  const targetUrl = `${redirectURl}?code=${code}${hash}`;
  window.location.replace(targetUrl);
}

function clearUrlToken() {
  setTimeout(() => {
    // 路由加载完成后，删掉url上的access_token参数
    let paramJson = urlToJson(window.location.search).paramJson;
    delete paramJson.code;
    delete paramJson.state;
    const paraStr = toQueryString(paramJson);

    const location = window.location;
    let replaceStateUrl =
      `${location.origin}${location.pathname}${location.hash}` +
      `${paraStr ? `?${paraStr}` : ""}`;
    // console.log(replaceStateUrl);

    window.history.replaceState(null, "", replaceStateUrl);
  }, 1000);
}

// 校验token
function checkTokenIdaas() {
  let token = getIdaasToken();
  if (!token) {
    goLoginIdaas();
    return "";
  } else {
    return token;
  }
}

/**
 * 获取accessToken
 * @param {*} code 登录返回的code码
 * @returns promise
 */
function getAccessToken(code) {
  return new Promise((resolve, reject) => {
    const json = {
      code: code,
    };
    loginApi(json)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 设置accessToken，到cookie中；1天过期
 * @param {*} accessToken
 */
function setAccessToken(accessToken) {
  if (!accessToken) {
    throw new Error(`accessToken不可为空`);
  }
  var inOneDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000); // 1天过期
  setCookie(TOKENKEY, accessToken, {
    path: "/",
    expires: inOneDay, // 过期时间（毫秒）
  });
}

// 获取idaas的登录token
function getIdaasToken() {
  return getCookie(TOKENKEY);
}

/**
 * 退出登录接口
 */
async function logout(callback = null) {
  await logOutApi()
  callback && callback()
  // goLoginIdaas()
  goLogOutThp()
  // const loginUrl = getLoginUrl()
  // const logoutUrl = `${staticData.idaasLogoutUrl}?redirect_url=${encodeURIComponent(loginUrl)}
  // `
  // window.location.href = logoutUrl
}

export {
  checkLogin,
  logout,
  checkTokenIdaas,
  getAccessToken,
  setAccessToken,
  getIdaasToken,
};
