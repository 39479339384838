/*
 * @Author: Mr.Hu
 * @Date: 2022-06-09 10:55:21
 * @Description:
 * @LastEditors: Mr.Hu
 */
const customerService = [
  {
    index: "1",
    title: "餐饮免品",
    icon: "el-icon-receiving",
    permissionValue: "customerServiceFreeTaste",
    children: [
      {
        index: "1-1",
        name: "FreeTasteVoucherVerifyList",
        title: "券核销",
        permissionValue: "customerSercieFreeTasteVoucherVerify",
      },
      {
        index: "1-2",
        name: "FreeTasteQrCode",
        title: "码查询",
        permissionValue: "customerSercieFreeTasterQrCode",
      },
    ],
  },
];

export { customerService };
