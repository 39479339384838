import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currRouteName: "", // 当前路由name
    deptList: JSON.parse(localStorage.getItem("deptList")) || "",
    deptId: localStorage.getItem("deptId") || "",
    deptName: localStorage.getItem("deptName") || "",
    deptLevel: localStorage.getItem("deptLevel") || "",
    deptOptions: [],
    isManager: localStorage.getItem("isManager") || 1,
    userInfo: {}, // 用户信息
    userAuthInfo: {}, // 用户权限信息，含角色
  },
  mutations: {
    SETCURRROUTENAME(state, data) {
      state.currRouteName = data;
    },
    SET_DEPTLIST(state, data) {
      state.deptList = data;
    },
    SET_DEPTNAME(state, data) {
      state.deptName = data;
    },
    SET_DEPTID(state, data) {
      state.deptId = data;
    },
    SET_DEPTLEVEL(state, data) {
      state.deptLevel = data;
    },
    SET_DEPTOPTIONS(state, data) {
      state.deptOptions = data;
    },
    SET_ISMANAGER(state, data) {
      state.isManager = data;
    },
    SET_USERINFO(state, data) {
      state.userInfo = data;
    },
    SET_USERAUTHINFO(state, data) {
      state.userAuthInfo = data;
    },
  },
  actions: {},
  modules: {},
});
