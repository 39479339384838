var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"sidebar-app"}},[_c('div',{staticClass:"nav c-flex-x-between"},[_c('div',{staticClass:"logo-part c-flex-x-center"},[_vm._m(0),_c('div',{staticClass:"c-flex-x-start module-group"},_vm._l((_vm.appList),function(item,index){return _c('div',{key:index,staticClass:"c-flex-x-center module",class:[{ active: _vm.currRouteName === item.defaultRouterName }],on:{"click":function($event){return _vm.jump(item.path)},"mouseenter":_vm.mouseEnter}},[_vm._v(" "+_vm._s(item.title || "")+" ")])}),0)]),_c('div',{staticClass:"setting-part c-flex-x-end"},[(
          _vm.msgInfo.openWineWaitAuditCount ||
          _vm.msgInfo.banquetFreeWaitAuditCount ||
          _vm.msgInfo.dianShangWaitAuditCount
        )?_c('div',{directives:[{name:"hasBtn",rawName:"v-hasBtn",value:('thpCenterInfo'),expression:"'thpCenterInfo'"}],staticClass:"c-flex-x-end newinfo",on:{"click":function($event){return _vm.jumpMicroApp(
            '/marketing-center#/chouCheck',
            'marketing-center',
            '/chouCheck'
          )}}},[_c('i',{staticClass:"el-icon-message-solid cfff"}),_c('span',[_vm._v("您有新的宴席审核")])]):_vm._e(),(_vm.deptOptions.length)?_c('div',{staticClass:"dept-info"},[_c('el-dropdown',{attrs:{"trigger":"hover"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link userinfo-inner"},[_vm._v(" "+_vm._s(_vm.deptOptions[0] ? _vm.deptOptions[0].name : "")+" ")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.deptOptions),function(item,index){return _c('el-dropdown-item',{key:index,attrs:{"command":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1):_vm._e(),_c('div',{staticClass:"userInfo"},[_c('el-dropdown',{attrs:{"trigger":"hover"}},[_c('span',{staticClass:"el-dropdown-link userinfo-inner"},[_c('i',{staticClass:"el-icon-user-solid"}),_vm._v("  "+_vm._s(_vm.userInfo.userName || ""))]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.msgCenter.apply(null, arguments)}}},[_vm._v("消息中心")]),_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.logoutClick.apply(null, arguments)}}},[_vm._v("退出登录")])],1)],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"system-name c-flex-x-center"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/nav/logo.png")}}),_vm._v(" 桃花坡 ")])
}]

export { render, staticRenderFns }