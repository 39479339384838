const supplyChain = [
  {
    index: "1",
    title: "订单中心",
    icon: "el-icon-s-order",
    permissionValue: "supplyChainOrderCenter",
    children: [
      // {
      //   index: "1-2",
      //   name: "PurchaseList",
      //   title: "进货单列表",
      //   permissionValue: "supplyChainPurchaseList",
      // },
      // {
      //   index: "1-3",
      //   name: "ShipmentList",
      //   title: "配货单列表",
      //   permissionValue: "supplyChainShipmentList",
      // },
      {
        index: "1-4",
        name: "Purchase",
        title: "进货单",
        permissionValue: "supplyChainPurchase",
      },
      {
        index: "1-5",
        name: "Sales",
        title: "销售单",
        permissionValue: "supplyChainSales",
      },
      {
        index: "1-111",
        name: "DzSales",
        title: "定制单",
        permissionValue: "supplyChainDzSales",
      },
      {
        index: "1-6",
        name: "ActWines",
        title: "活动用酒单",
        permissionValue: "supplyChainActWineList",
      },
      {
        index: "1-7",
        name: "Inventory",
        title: "盘亏单",
        permissionValue: "supplyChainInventory",
      },
      {
        index: "1-8",
        name: "CodeWine",
        title: "五码用酒申请单",
        permissionValue: "supplyChainFiveCodeWineApply",
      },
      {
        index: "1-9",
        name: "Material",
        title: "物料申请单",
        permissionValue: "supplyChainMaterialApply",
      },
      {
        index: "1-10",
        name: "Replacement",
        title: "补发订单",
        permissionValue: "supplyChainReplacement",
      },
      {
        index: "1-11",
        name: "OnlineBusiness",
        title: "电商单",
        permissionValue: "supplyChainOnlineBusiness",
      },
      {
        index: "1-12",
        name: "OderConfigure",
        title: "订单配置",
        permissionValue: "supplyChainOrderConfigure",
      },
    ],
  },

  {
    index: "2",
    title: "发货中心",
    icon: "el-icon-shopping-cart-1",
    permissionValue: "supplyChainSendCenter",
    children: [
      {
        index: "2-1",
        name: "LogisticsList",
        title: "待发货明细表",
        permissionValue: "supplyChainLogisticsList",
      },
      {
        index: "2-2",
        name: "LogisticsDetail",
        title: "发货明细表",
        permissionValue: "supplyChainLogisticsDetail",
      },
      {
        index: "2-3",
        name: "SendError",
        title: "发货异常提醒",
        permissionValue: "supplyChainSendError",
      },
      {
        index: "2-4",
        name: "GoStoreManage",
        title: "入库管理",
        permissionValue: "supplyChainGoStoreManage",
      },
    ],
  },
  {
    index: "3",
    title: "补货中心",
    icon: "el-icon-shopping-cart-1",
    permissionValue: "supplyChainReplenishmentCenter",
    children: [
      {
        index: "3-1",
        name: "DistributionPlan",
        title: "补货计划",
        permissionValue: "supplyChainDistributionPlan",
      },
      {
        index: "3-2",
        name: "DistributionPlanOrder",
        title: "补货计划单",
        permissionValue: "supplyChainDistributionPlanOrder",
      },
    ],
  },
  {
    index: "10",
    title: "履约中心",
    icon: "el-icon-s-ticket",
    permissionValue: "supplyChainPerformanceCenter",
    children: [
      {
        index: "10-1",
        name: "PerformanceMonitor",
        title: "履约监控",
        permissionValue: "supplyChainPerformanceCenterMonitor",
      },
      {
        index: "10-2",
        name: "PerformanceStrategy",
        title: "履约策略",
        permissionValue: "supplyChainPerformanceTactics",
      },
      {
        index: "10-3",
        name: "PerformanceManage",
        title: "履约单管理",
        permissionValue: "supplyChainPerformanceManage",
        children: [
          {
            index: "10-3-1",
            name: "OrderFulfillment",
            title: "订单履约",
            permissionValue: "supplyChainPerformanceOrderFulfillment",
          },
          {
            index: "10-3-2",
            name: "CustomOrderFulfillment",
            title: "定制单履约",
            permissionValue: "supplyChainPerformanceCustomOrderFulfillment",
          },
        ],
      },
      {
        index: "10-4",
        name: "ZhengZhouUrban",
        title: "郑州城配",
        permissionValue: "supplyChainZhengZhouUrban",
      },
    ],
  },
  {
    index: "4",
    title: "库存管理",
    icon: "el-icon-shopping-cart-2",
    permissionValue: "supplyChainWareCenter",
    children: [
      {
        index: "4-1",
        name: "allocationManage",
        title: "调拨管理",
        permissionValue: "supplyChainAllocationManage",
      },
      {
        index: "4-2",
        name: "houseList",
        title: "其他出入库",
        permissionValue: "supplyChainHouseList",
      },
      {
        index: "4-3",
        name: "pointStock",
        title: "网点库存",
        permissionValue: "supplyChainPointStock",
      },
      // {
      //   index: "4-5",
      //   name: "ConfirmDelivery",
      //   title: "确认收货记录",
      //   permissionValue: "supplyChainConfirmDelivery",
      // },
      {
        index: "4-6",
        name: "WarehouseInventory",
        title: "仓库库存",
        permissionValue: "supplyChainWarehouseInventory",
        children: [
          {
            index: "4-6-1",
            name: "CommodityInventory",
            title: "商品库存",
            permissionValue: "supplyChainCommodityInventory",
          },
          {
            index: "4-6-2",
            name: "InventoryAlert",
            title: "库存预警",
            permissionValue: "supplyChainInventoryAlert",
          },
        ],
      },
      {
        index: "4-7",
        name: "BusinessInventory",
        title: "业务库存",
        permissionValue: "supplyChainBusinessInventory",
      },
      {
        index: "4-8",
        name: "SendBackGoodsRejected",
        title: "退货入库",
        permissionValue: "supplyChainSendBackGoodsRejected",
      },
    ],
  },
  {
    index: "5",
    title: "仓储管理",
    icon: "el-icon-s-platform",
    permissionValue: "supplyChainCangWareCenter",
    children: [
      {
        index: "5-1",
        name: "houseOwnerManage",
        title: "货主管理",
        permissionValue: "supplyChainHouseOwnerManage",
      },
      {
        index: "5-2",
        name: "houseOwner",
        title: "仓库管理",
        permissionValue: "supplyChainHouseOwner",
      },
      {
        index: "5-3",
        name: "HouseGoods",
        title: "仓库商品",
        permissionValue: "supplyChainHouseGoods",
      },
    ],
  },
  {
    index: "6",
    title: "销售出库单",
    icon: "el-icon-s-ticket",
    permissionValue: "supplyChainToSaleCenter",
    children: [
      {
        index: "6-1",
        name: "erplist",
        title: "销售出库单",
        permissionValue: "supplyChainErplist",
      },
      {
        index: "6-2",
        name: "erpcglist",
        title: "采购入库单",
        permissionValue: "supplyChainErpcglist",
      },
    ],
  },
  {
    index: "7",
    title: "商品中心",
    icon: "el-icon-s-finance",
    permissionValue: "supplyChainGoodsCenter",
    children: [
      {
        index: "7-1",
        name: "commodityList",
        title: "商品列表",
        permissionValue: "supplyChainGoodsCenterList",
      },
      {
        index: "7-2",
        name: "CommodityGroup",
        title: "商品分组",
        permissionValue: "supplyChainGroupTable",
      },
      {
        index: "7-3",
        name: "SubstituteManagement",
        title: "替代品管理",
        permissionValue: "supplyChainSubstituteManagement",
      },
      {
        index: "7-4",
        name: "WMSMapping",
        title: "WMS映射",
        permissionValue: "supplyChainWMSMapping",
      },
      {
        index: "7-5",
        name: "ThirdJdMapping",
        title: "三方仓映射",
        permissionValue: "supplyChainThirdJdMapping",
      },
      {
        index: "7-6",
        name: "GuanYiMapping",
        title: "管易云商品映射",
        permissionValue: "supplyChainGuanYiMapping",
      },
      {
        index: "7-7",
        name: "SyncAssistant",
        title: "同步助手",
        permissionValue: "supplyChainSyncAssistant",
      },
    ],
  },
  {
    index: "8",
    title: "物流服务",
    icon: "el-icon-s-finance",
    permissionValue: "supplyChainLvOrderCenter",
    children: [
      {
        index: "8-1",
        name: "northList",
        title: "物流运单",
        permissionValue: "supplyChainNorthList",
      },
    ],
  },
  {
    index: "9",
    title: "售后中心",
    icon: "el-icon-s-finance",
    permissionValue: "supplyChainAftermarket",
    children: [
      {
        index: "9-1",
        name: "SaleOrderCancel",
        title: "销售订单取消",
        permissionValue: "supplyChainSaleOrderCancel",
      },
      // {
      //   index: "9-2",
      //   name: "ServiceOrder",
      //   title: "服务单",
      //   permissionValue: "serviceOrder",
      // },
      {
        index: "9-3",
        name: "RefundOrder",
        title: "取消退款单",
        permissionValue: "refundOrder",
      },
      // {
      //   index: "9-4",
      //   name: "ReturnOrder",
      //   title: "退货单",
      //   permissionValue: "returnOrder",
      // },
      // {
      //   index: "9-5",
      //   name: "ReissueOrder",
      //   title: "补发单",
      //   permissionValue: "reissueOrder",
      // },
      // {
      //   index: "9-6",
      //   name: "DifferenceOrder",
      //   title: "差异单",
      //   permissionValue: "differenceOrder",
      // },
    ],
  },
  {
    index: "11",
    title: "码中心",
    icon: "el-icon-s-grid",
    permissionValue: "supplyChainCodeCenter",
    children: [
      {
        index: "11-1",
        name: "CodeTraceability",
        title: "码溯源",
        permissionValue: "supplyChainCodeTraceability",
      },
    ],
  },
];

export { supplyChain };
