import { callApiFloor } from "@/api/callApi";

// 获取各个接口地址
import { login, getSaleArea, getPartId, getUserDepts } from "@/api/apiList";
import { getIdaasToken } from "@/utils/business/login/login";

// const baseApi = gateWayConfig.BASE_URL;
// const gateWay = gateWayConfig.GATEWAY_URL;
const axios = callApiFloor('BASE_URL');
const axios_gateWay = callApiFloor('GATEWAY_URL');

// 登录接口
export function loginApi(data) {
  return axios({
    url: login,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// 查询partId接口
export function getPartIdApi(data) {
  return axios_gateWay({
    url: getPartId,
    headers: {
      xtoken: getIdaasToken()
    },
    method: "post",
    data: data,
  });
}

// 根据用户id查询用户角色接口
export function getUserDeptsApi(data) {
  return axios_gateWay({
    url: getUserDepts,
    headers: {
      xtoken: getIdaasToken()
    },
    method: "get",
    data: data,
  });
}

// 判断是不是业务区的人员接口
export function getSaleAreaApi(data) {
  return axios_gateWay({
    url: getSaleArea,
    headers: {
      xtoken: getIdaasToken()
    },
    method: "get",
    data: data,
  });
}
