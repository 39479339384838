import {
  getPermissions,
} from "@/utils/business/auth/authIdass";
function handleArr(arr){
  if(Object.prototype.toString.call(arr) === '[object Array]'){
    return arr
  }
  return [arr]
}
// 脱敏金额
export const amount = {
  inserted(el, binding) {
    const { value } = binding
    
    const btn_permission = handleArr(value);
    const permissions = getPermissions();
    var hasPermissions = permissions.some(item => {
      return btn_permission.includes(item.permissionValue) 
    })
    
    if (!hasPermissions) {
      // const html = el.innerHTML.trim();
      el.innerHTML = '******';
    }
  }
}
// 脱敏账户名
export const accountName = {
  inserted(el, binding) {
    const { value } = binding
    
    const btn_permission = handleArr(value);
    const permissions = getPermissions();
    var hasPermissions = permissions.some(item => {
      return btn_permission.includes(item.permissionValue) 
    })
    
    if (!hasPermissions) {
      let html = el.innerHTML.trim();
      const len = html.length;
      
      if(len === 2) {
        html = html.substr(0,1) + '*';
      } else if(len > 2){
        html = html.substr(0,2) + '*';
      }
      el.innerHTML = html;
    }
  }
}

// 脱敏账号
export const accountNumber = {
  inserted(el, binding) {
    const { value } = binding
    
    const btn_permission = handleArr(value);
    const permissions = getPermissions();
    var hasPermissions = permissions.some(item => {
      return btn_permission.includes(item.permissionValue) 
    })
    
    if (!hasPermissions) {
      let html = el.innerHTML.trim();
      const len = html.length - 8;
      if(len > 0) {
        html = html.substr(0,4) + new Array(len).fill('*').join('') + html.split('').slice(-4).join('');
      }
      el.innerHTML = html;
    }
  }
}