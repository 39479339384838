import { callApi } from "@/api/callApi";
import geteWay from '@/config/gateway.config'

// 获取各个接口地址
import { menu } from "@/api/apiList";

// 静态全局变量
import { staticData } from "@/config/base.config";
import { getIdaasToken } from "@/utils/business/login/login";

const axiosBff = callApi(geteWay.BFF);

// 权限接口
export function authApi(data) {
  return axiosBff({
    url: menu,
    method: "get",
    data: data,
    headers: {
      "accesstoken": getIdaasToken(),
      "appid": staticData.appid,
    },
    config: {
      noIntercept: true
    }
  });
}
