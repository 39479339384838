/*
 * @Author: Mr.Hu
 * @Date: 2022-06-09 10:55:21
 * @Description:
 * @LastEditors: Mr.Hu
 */
const businessOnline = [
  // {
  //   index: "1",
  //   title: "订单中心",
  //   icon: "el-icon-receiving",
  //   permissionValue: "businessOnlineOrderCenter",
  //   children: [
  //     {
  //       index: "1-1",
  //       name: "NewOrderList",
  //       title: "电商新增单",
  //       permissionValue: "businessOnlineNewOrder",
  //     },
  //   ],
  // },
  {
    index: "2",
    title: "电商订单",
    icon: "el-icon-s-order",
    permissionValue: "businessOnlineECommerceOrder",
    children: [
      {
        index: "2-1",
        name: "ECommerceSalesOrder",
        title: "电商销售订单",
        permissionValue: "businessOnlineECommerceSalesOrder",
        children: [
          {
            index: "2-1-1",
            name: "GiveawayRegistration",
            title: "桃花坡推送订单",
            permissionValue: "businessOnlineGiveawayRegistration",
          },
          {
            index: "2-1-2",
            name: "PushOutOfStockOrders",
            title: "桃花坡推送缺货订单",
            permissionValue: "businessOnlinePushOutOfStockOrders",
          },
          {
            index: "2-1-3",
            name: "PushPreSaleOrders",
            title: "桃花坡推送预售订单",
            permissionValue: "businessOnlinePushPreSaleOrders",
          },
        ],
      },
      {
        index: "2-2",
        name: "ErrorReplacementOrder",
        title: "异常补发订单",
        permissionValue: "businessOnlineErrorReplacementOrder",
        children: [
          {
            index: "2-2-1",
            name: "DamagedOrderList",
            title: "破损补发订单",
            permissionValue: "businessOnlineDamagedOrder",
          },
          {
            index: "2-2-2",
            name: "LeakageOrderList",
            title: "漏液补发订单",
            permissionValue: "businessOnlineLeakageOrder",
          },
          {
            index: "2-2-3",
            name: "MiscommunicationOrderList",
            title: "漏发/掉发订单",
            permissionValue: "businessOnlineMiscommunicationOrder",
          },
          {
            index: "2-2-4",
            name: "MissedOrderList",
            title: "错发补发订单",
            permissionValue: "businessOnlineMissedOrder",
          },
          {
            index: "2-2-5",
            name: "CompensationOrder",
            title: "赔付订单",
            permissionValue: "businessOnlineCompensationOrder",
          },
        ],
      },
      {
        index: "2-3",
        name: "OperationSApplicationShipmentOrder",
        title: "运营申请发货订单",
        permissionValue: "businessOnlineOperationSApplicationShipmentOrder",
        children: [
          {
            index: "2-3-1",
            name: "ChannelOperationOrders",
            title: "渠道运营订单",
            permissionValue: "businessOnlineChannelOperationOrders",
          },
          {
            index: "2-3-2",
            name: "ConsumerActivitiesOrders",
            title: "消费者活动订单列表",
            permissionValue: "businessOnlineConsumerActivitiesOrders",
          },
        ],
      },
    ],
  },
  {
    index: "3",
    title: "订单管理",
    icon: "el-icon-s-order",
    permissionValue: "businessOnlineOrderManage",
    children: [
      {
        index: "3-1",
        name: "OrderManageRelateOrder",
        title: "关联电商订单",
        permissionValue: "businessOnlineOrderManageRelateList",
      },
    ],
  },
];

export { businessOnline };
