import {
	amount,
	accountName,
	accountNumber,
} from './utils/other/desensitization';

const install = function (Vue) {
	Vue.directive('amount', amount);
	Vue.directive('accountName', accountName);
	Vue.directive('accountNumber', accountNumber);
};
if (window.Vue) {
	Vue.use(install); // eslint-disable-line
}
export default install;
