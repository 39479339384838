const tradeCenter = [
  {
    index: "0",
    title: "查询中心",
    icon: "el-icon-receiving",
    permissionValue: "tradeCenterSearchCenter",
    children: [
      {
        index: "0-1",
        name: "distributionRestore",
        title: "配货额度恢复",
        permissionValue: "tradeCenterDistributionQuotaRestored",
      },
      {
        index: "0-2",
        name: "voucherWriteOff",
        title: "券核销",
        permissionValue: "tradeCenterVoucherWriteOff",
      },
      {
        index: "0-3",
        name: "ffcOrderList",
        title: "履约业务单",
        permissionValue: "tradeCenterFfcOrderList",
      },
      {
        index: "0-4",
        name: "ffcBillList",
        title: "履约账单",
        permissionValue: "tradeCenterFfcBillList",
      },
      {
        index: "0-5",
        name: "paymentList",
        title: "支付单列表",
        permissionValue: "tradeCenterPaymentList",
      },
      {
        index: "0-6",
        name: "refundList",
        title: "支付单退款",
        permissionValue: "tradeCenterRefundList",
      },
      {
        index: "0-7",
        name: "waterList",
        title: "请求流水",
        permissionValue: "tradeCenterWaterList",
      },
    ],
  },
  {
    index: "1",
    title: "财务中心",
    icon: "el-icon-receiving",
    permissionValue: "tradeCenterMoneyCenter",
    children: [
      {
        index: "1-1",
        name: "RebateManage",
        title: "分账明细",
        permissionValue: "tradeCenterRebateManage",
      },
      // {
      //   index: "1-2",
      //   name: "RebateReconiliation",
      //   title: "订单产品返利对账",
      // },
      // 分账流水 查询数据太慢，暂时下线
      // {
      //   index: "1-3",
      //   name: "Trouting",
      //   title: "分账流水",
      //   permissionValue: "tradeCenterTrouting",
      // },
      // {
      //   index: "1-4",
      //   name: "DailyReconciliation",
      //   title: "第三方付款流水对账",
      // },
      {
        index: "1-5",
        name: "ReturnList",
        title: "回款上报单",
        permissionValue: "tradeCenterReturnList",
      },
      {
        index: "1-6",
        name: "BankStatement",
        title: "银行流水",
        permissionValue: "tradeCenterBankStatement",
      },
      {
        index: "1-7",
        name: "BankAccount",
        title: "银行账户",
        permissionValue: "tradeCenterBankAccount",
      },
      {
        index: "1-8",
        name: "ChannelRouting",
        title: "通道路由",
        permissionValue: "tradeCenterChannelRouting",
      },
      {
        index: "1-9",
        name: "SceneManage",
        title: "场景管理",
        permissionValue: "tradeCenterSceneManage",
      },
    ],
  },
  {
    index: "2",
    title: "基础数据配置",
    icon: "el-icon-s-tools",
    permissionValue: "tradeCenterBaseConfig",
    children: [
      {
        index: "2-1",
        name: "MainConfig",
        title: "主体信息配置",
        permissionValue: "tradeCenterMainConfig",
      },
      {
        index: "2-2",
        name: "RebateRule",
        title: "收益规则配置",
        permissionValue: "tradeCenterRebateRule",
      },
      {
        index: "2-3",
        name: "MerchantPolling",
        title: "商户轮询",
        permissionValue: "tradeCenterMerchantPolling",
      },
    ],
  },
  {
    index: "5",
    title: "券中心",
    icon: "el-icon-receiving",
    permissionValue: "tradeCenterQuanCenter",
    children: [
      {
        index: "5-1",
        name: "QuanCenter",
        title: "模板管理",
        permissionValue: "tradeCenterQuanCenterManage",
      },
      {
        index: "5-2",
        name: "activity",
        title: "发放批次",
        permissionValue: "tradeCenterQuanCenterEmit",
      },
      {
        index: "5-3",
        name: "quanRecord",
        title: "领取记录",
        permissionValue: "tradeCenterQuanCenterRecord",
      },
    ],
  },
  {
    index: "3",
    title: "出金管理",
    icon: "el-icon-money",
    permissionValue: "tradeCenterMealPay",
    children: [
      {
        index: "3-1",
        name: "MoneyConfig",
        title: "出金配置",
        permissionValue: "tradeCenterMoneyConfig",
      },
      {
        index: "3-2",
        name: "MoneyDetail",
        title: "出金明细",
        permissionValue: "tradeCenterMoneyDetail",
      },
    ],
  },
  {
    index: "8",
    title: "对账中心",
    icon: "el-icon-s-ticket",
    permissionValue: "tradeCenterChcekMoney",
    children: [
      {
        index: "8-1",
        name: "dzproject",
        title: "对账项目",
        permissionValue: "tradeCenterChcekMoneyProject",
      },
      {
        index: "8-2",
        name: "dzresult",
        title: "对账结果",
        permissionValue: "tradeCenterChcekMoneyResult",
      },
      {
        index: "8-3",
        name: "dzFile",
        title: "对账文件",
        permissionValue: "tradeCenterChcekMoneyResult",
      },
    ],
  },
  {
    index: "6",
    title: "账户中心",
    icon: "el-icon-money",
    permissionValue: "tradeCenterAccountCr",
    children: [
      {
        index: "6-1",
        name: "accountManage",
        title: "账户管理",
        permissionValue: "tradeCenterAccountCrListPage",
      },
      {
        index: "6-2",
        name: "changeAcount",
        title: "账户流水",
        permissionValue: "tradeCenterAccountCrLsListPage",
      },
      {
        index: "6-3",
        name: "microAccountManage",
        title: "小微账户管理",
        permissionValue: "tradeCenterMicroAccountCrLsListPage",
      },
    ],
  },
  {
    index: "7",
    title: "客户账单",
    icon: "el-icon-wallet",
    permissionValue: "tradeCenterBillCenter",
    children: [
      {
        index: "7-1",
        name: "ClientBillConfig",
        title: "账单配置",
        permissionValue: "tradeCenterClientBillConfigPage",
      },
      {
        index: "7-2",
        name: "ClientBillList",
        title: "导出列表",
        permissionValue: "tradeCenterClientBillListPage",
      },
    ],
  },
];

export { tradeCenter };
