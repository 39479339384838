/* eslint-disable */

import axios from "axios"; // 文档：http://www.axios-js.com/zh-cn/docs/#axios-create-config
import Qs from "qs";
import Cookies from "js-cookie"; // 第三方cookie插件：文档地址：https://github.com/js-cookie/js-cookie

import router from "@/router";

import { toQueryString } from "@/utils/common/url_query";
import gateWayConfig from "@/config/gateway.config";
import { showTip } from "@/utils";
import { staticData } from "@/config/base.config";
// 获取各个接口地址
import { logout } from "@/api/apiList";

/* 自定义Content-Type方式示例如下，默认'Content-Type': 'application/json'
  const hostDev = baseHost.hostDev
  const axios_www = callApi(hostDev, {
    'Content-Type': 'application/x-www-form-urlencoded',
  });
*/
const callApi = (apiRoot = "/", headers) => {
  let obj = {};
  let axiosDefault = {
    baseURL: apiRoot,
    // timeout: 20000,
    timeout: 50000, // 请求超时时间
    headers: {
      "Content-Type": "application/json",
    },
  };

  let defHeaders = axiosDefault.headers;

  // 合并传入的header和默认的header
  if (headers) {
    defHeaders = Object.assign(defHeaders, headers); // 合并header
  }

  // 整理最终headers
  axiosDefault.headers = defHeaders;

  // 设置axios
  obj = axios.create(axiosDefault);

  // 添加请求拦截器
  obj.interceptors.request.use(
    (config) => {
      // token拦截(某些接口不拦截)
      if (!(config.config && config.config.noIntercept)) {
        const token = checkToken(config);
        config.headers.token = token;
      }

      // 针对get参数做处理(兼容get情况传入data的情况)
      if (config.method == "get" && config.data) {
        let paramsObj = config.params ? config.params : {};
        paramsObj = Object.assign(paramsObj, config.data);

        config.params = paramsObj;
      }

      // 针对post模式对象做x-www-form-urlencoded处理
      if (
        config.data &&
        config.headers["Content-Type"] == "application/x-www-form-urlencoded" &&
        Object.prototype.toString.call(config.data).slice(8, -1) == "Object"
      ) {
        config.data = Qs.stringify(config.data);
      }

      // 针对post模式对象做form-data处理
      if (
        config.data &&
        config.headers["Content-Type"] == "multipart/form-data" &&
        Object.prototype.toString.call(config.data).slice(8, -1) == "Object"
      ) {
        let formData = new FormData();
        for (let key in config.data) {
          formData.append(key, config.data[key]);
        }

        config.data = formData;
      }

      return config;
    },
    (err) => {
      // 对请求错误做些什么
      return Promise.reject(err);
    }
  );

  // 添加响应拦截器
  obj.interceptors.response.use(
    (res) => {
      let config = res.config; // 请求相关信息
      let data = res.data; // 返回数据

      let code = getCode(data); // 兼容code码
      code = checkCode(code);

      // 判断登录信息是否过期(登录过期；用户没登录)
      if (code === 200 || config.responseType === "blob") {
        return data;
      } else if (code === 401 || code == `001`) {
        setTimeout(() => {
          goLoginIdaas();
        }, 3000);
      } else {
        console.log("---------");
        console.log("问题接口：" + (config.baseURL + config.url));
        console.log("问题返回：");
        console.log(data);
        console.log("---------");

        // if (data.errMsg || data.message || data.msg) {
        errorDialogShow(data);
        // }

        // 响应错误
        // return Promise.reject(data);
        return data;
      }
    },
    (err) => {
      // console.log(err.response)

      const response = err.response;
      // 锁定接口为 menu ，并且http状态码为 403 或 401 ，则代表assesstoken过期，跳转登录页
      if (
        response.config.url === "/user/menu" &&
        (response.status === 403 || response.status === 401)
      ) {
        // goLoginIdaas();
        setTimeout(() => {
          goLogout();
        }, 3000);
      }

      // 对响应错误做点什么
      return Promise.reject(err);
    }
  );

  return obj;
};

const callApiFloor = (urlType = "BASE_URL") => {
  const baseApi = gateWayConfig[urlType];
  let headers = getHeaders(urlType);
  return callApi(baseApi, headers);
};
function getCookie(key, config = {}) {
  const defaultConfig = {
    path: "/",
  };
  Object.assign(defaultConfig, config);
  return Cookies.get(key, defaultConfig);
}
function getHeaders(urlType) {
  // let token = getStorageValue("token");
  const xtoken = getCookie('thp_idassToken');
  let account = getStorageValue("account");
  let xLoginSession = getStorageValue("x-login-session") || account;
  const sid = "4c3adb4d-9190-4447-969a-9ac40af19d9b";

  let headers = {
    // token: token,
    xtoken,
    userInfo: account ? encodeURIComponent(account) : "",
    "x-login-session": xLoginSession,
    sid: sid,
  };
  if (urlType === "GATEWAY_URL") {
    headers["wow-login-user"] = getStorageValue("wow-login-user");
  }

  return headers;
}
// 获取localStorage值
function getStorageValue(key) {
  const storageVals = {
    token: localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : "",
    account: localStorage.getItem("account")
      ? JSON.parse(localStorage.getItem("account"))
      : "",
    "x-login-session": localStorage.getItem("x-login-session") || "",
    "wow-login-user": localStorage.getItem("wow-login-user") || "",
  };
  return storageVals[key];
}

// 校验token
function checkToken(config) {
  let token = getStorageValue("token");
  if (!token) {
    goLoginIdaas();
    return false;
  } else {
    return token;
  }
}

// 跳转登录页
// function goLogin() {
//   router.push({ path: "/login" });
// }
// 跳转登录页
function goLoginIdaas() {
  const loginUrl = getLoginUrl();
  window.location.href = loginUrl;
}
function getLoginUrl() {
  // const redirectUrl = `${window.location.origin}/main`
  const redirectUrl = `${window.location.origin}${window.location.pathname}`; // 回跳地址设置成当前导航的首页
  const loginUrl =
    staticData.idaasLoginUrl + `&state=${encodeURIComponent(redirectUrl)}`;
  return loginUrl;
}
// 跳出桃花坡
function goLogOutThp() {
  const redirectUrl = `${window.location.origin}${window.location.pathname}`; // 回跳地址设置成当前导航的首页
  const logoutUrl = `${
    staticData.idaasLogoutUrl
  }&prompt=login&state=${encodeURIComponent(redirectUrl)}`;
  console.log("logoutUrl===", logoutUrl);

  window.location.href = logoutUrl;
  // window.location.href = 'http://47.97.83.98/public/sp/slo/idaasplugin_oauth23?redirect_url=http%3A%2F%2F47.97.83.98%2Foauth%2Fauthorize%3Fresponse_type%3Dcode%26scope%3Dread%26client_id%3D05bed4d4cc73dbf8e54902e4005ee193gjX0h3snFYs%26redirect_uri%3Dhttps%253A%252F%252Fncenter.test.tanjiu.cn%252Fmain%26state%3Dhttp%253A%252F%252Flocalhost%253A8080%252Fmain%252Fsupply-chain&prompt=login'
}
function errorDialogShow(data) {
  const msg = getErrorMsg(data);
  showTip(msg, "warning");
}
function getErrorMsg(data) {
  const msg =
    data.errMsg ||
    data.message ||
    data.msg ||
    data.hint ||
    "操作未成功，请联系管理员";

  return msg;
}

function getCode(results) {
  let code = results.code || results.resultCode;
  if (results.resultCode === 0) {
    code = results.resultCode;
  } else if (results.errCode === 0) {
    code = results.errCode;
  } else if (results.code === 0) {
    code = results.code;
  }
  if (results.retdata) {
    code = results.errCode;
  }

  return code;
}

function checkCode(code) {
  let resultCode = code;

  if (
    code === "success" ||
    code === "1" ||
    code === 0 ||
    code == "0" ||
    code == "0000" ||
    code == 200 ||
    code == "success"
  ) {
    resultCode = 200;
  }

  // console.log(resultCode)

  return resultCode;
}

async function goLogout() {
  // await logOutApi()
  goLogOutThp();
}
// function logOutApi(data) {
//   return axiosBff({
//     url: logout,
//     method: "post",
//     data: data,
//     headers: {
//       "accesstoken": getIdaasToken(),
//       appid: staticData.appid,
//     },
//     config: {
//       noIntercept: true
//     }
//   });
// }

export {
  callApi,
  callApiFloor,
  checkToken,
  goLoginIdaas,
  getLoginUrl,
  goLogOutThp,
};
