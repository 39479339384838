/* eslint-disable */

// 开发环境地址(npm run serve)
const devHost = {
  // 接口地址域名相关
  BASE_URL: '/apis',
  DEV_URL: '/dev',
  MAP_URL: '/map',
  GATEWAY_URL: '/gateWay',
  PURCHASE_URL: '/purchaseGateWay',
  ADDRETURN_URL: '/returnadd',
  PHP_URl: '/phpUrl',
  CRM_URl: '/crmUrl',
  V_HOME: '/vHome',
  ONLINE: '/online',
  CRMAPI: '/crmapi',
  PRODUCTAPI: '/productapi',
  CHECKMISS: '/rest/stock',
  TITOKAPI: '/api/tool',
  TLOG_URL: '/TLOG_URL',
  // BFF: `/bff`,
  BFF: `https://thp.test.tanjiu.cn/rest/bff`,
};

// 测试环境地址(npm run test)
const testHost = {
  // 接口地址域名相关
  BASE_URL: '//ncenterapi.test.tanjiu.cn/',// 基本服务
  DEV_URL: '//clue.test.tanjiu.cn/',// 招商服务
  MAP_URL: '//ncenter.test.tanjiu.cn/map/',// 腾讯地图
  ADDRETURN_URL: '//pay.test.tanjiu.cn/',// 新增T+1返利分账接口
  GATEWAY_URL: '//gateway.test.tanjiu.cn/',// 网关服务
  PURCHASE_URL: '//gateway.test.tanjiu.cn/zeusDms/', // 进货单列表网关服务
  PHP_URl: '//gateways.test.tanjiu.cn',// php服务
  CRM_URl: 'https://wine.test.tanjiu.cn/',// CRMphp服务
  V_HOME: '//vhome-ncenter.test.tanjiu.cn/',// CRMphp服务
  ONLINE: '//online.test.tanjiu.cn/',
  CRMAPI: '//crmapi.test.tanjiu.cn',
  PRODUCTAPI: '//gateway.test.tanjiu.cn/product/',
  CHECKMISS: '//vhome.test.tanjiu.cn/rest/stock/', //盈亏支付接口(PHP)  
  TITOKAPI: '//online.test.tanjiu.cn/api/tool/', //抖音排名接口
  TLOG_URL: 'https://sts.test.tanjiu.cn/rest/tracking/collect', //神策监控Url
  BFF: `https://thp.test.tanjiu.cn/rest/bff`,
};

// 线上环境地址(npm run build)
const proHost = {
  BASE_URL: '//ncenterapi.tanjiu.cn',
  DEV_URL: '//clue.tanjiu.cn/',
  MAP_URL: '//tc.tanjiu.cn/map/',
  ADDRETURN_URL: '//pay.tanjiu.cn/',// 新增T+1返利分账接口
  GATEWAY_URL: '//gateway.tanjiu.cn/',// 网关服务
  PURCHASE_URL: '//gateway.tanjiu.cn/zeusDms/', // 进货单列表网关服务
  PHP_URl: '//gateways.tanjiu.cn',// php服务
  CRM_URl: 'https://wine.tanjiu.cn',// CRMphp服务
  V_HOME: '//vhome-ncenter.tanjiu.cn/',// CRMphp服务
  ONLINE: '//online.tanjiu.cn',
  CRMAPI: '//crmapi.tanjiu.cn',
  PRODUCTAPI: '//gatewayali.tanjiu.cn/product/',
  CHECKMISS: '//vhome.tanjiu.cn/rest/stock/', //盈亏支付接口(PHP)
  TITOKAPI: '//online.tanjiu.cn/api/tool/', //抖音排名接口
  TLOG_URL: 'https://sts.tanjiu.cn/rest/tracking/collect', //神策监控Url
  BFF: `https://thp.tanjiu.cn/rest/bff`,
};

// 区分环境选择静态资源地址
const env = process.env.VUE_APP_ENV;

let exportConfig = "";
if (env === "production") {
  exportConfig = proHost;
} else if (env === "test") {
  exportConfig = testHost;
} else {
  exportConfig = devHost;
}

export default exportConfig;
