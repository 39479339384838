/* eslint-disable */

import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import { check } from "./check";

import store from "@/store";

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const router = new VueRouter({
  base: "/main",
  mode: "history",
  routes
});

// 路由前置守卫，在载入前
router.beforeEach((to, from, next) => {
  // cache当前路由名 =》全局使用
  store.commit("SETCURRROUTENAME", to.name || "");

  // 所有页面的路由守卫，在页面载入前的校验，通过校验，才会进入当前页面的模版组件
  if (!check(to, router)) return;

  next();
});

// 路由后置守卫，在载入后
router.afterEach((route) => {
  // console.log(route)
});

export default router;
