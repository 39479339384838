<template>
  <div id="app-container-main">
    <Sidebar v-if="showSadeBar"></Sidebar>
    <div class="router-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar/Sidebar.vue";

import {
  checkTokenIdaas,
  getAccessToken,
  setAccessToken,
} from "@/utils/business/login/login";

// import { urlToJson, toQueryString } from "@/utils/common/url_query";

export default {
  components: {
    Sidebar,
  },
  data() {
    return {};
  },
  computed: {
    showSadeBar() {
      // 不展示顶部sadebar的黑名单(router的name)
      const blackList = ["Login"];
      return !blackList.includes(this.$store.state.currRouteName);
    },
  },
  created() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scroped>
#app-container-main {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
