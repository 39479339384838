/**
 * 权限逻辑模块
 */

import store from "@/store";

// 公用方法
import { objToArray, arrayToObj } from "@/utils";
import menuData from "./utils/pageRouter";

// import { idaasData } from "./utils/mock/idaasJson";

// api接口
import { authApi } from "@/utils/business/auth/service/authService";

// 获取当前用户权限数据
async function idaasAuth() {
  const authInfo = await authApi();
  return authInfo;
}

// 当前用户是否有系统访问权限
function hasAuth(userAuthInfo) {
  let hasAuth = false;
  if (
    userAuthInfo &&
    userAuthInfo.data &&
    userAuthInfo.data.permissions &&
    userAuthInfo.data.permissions.length
  ) {
    hasAuth = true;
  }
  return hasAuth;
}

// 模块顶部导航json
function moduleMapJson() {
  return menuData[`mainRouter`];
}

// 获取权限数据（权限数据在main.js中，vue初始化前就存储到了vuex中）
function getPermissions() {
  return store.state.userAuthInfo.permissions;
  // return idaasData.permissions;
}

// 获取当前权限的第一个r一级outer路由
function getAuthFirstRouter() {
  // 获取权限菜单列表中的第一个主应用菜单的permissionValue
  const mainAuthRouter = getPermissions();
  const mainAuthRouterTarget = mainAuthRouter.filter(
    (item) => !item.parentPermissionUuid
  );
  const permissionValue = mainAuthRouterTarget.length
    ? mainAuthRouterTarget[0].permissionValue
    : "";

  let router = {};
  // 从所有菜单中找当前第一个有权限的菜单
  const mainRouter = moduleMapJson();
  mainRouter.forEach((item) => {
    if (item.permissionValue === permissionValue) {
      router = item;
    }
  });
  return router;
}

/**
 * 主应用：权限处理
 * @returns 返回当前用户权限导航列表
 */
function getAppList(moduleMap) {
  const permissions = getPermissions();
  // 权限列表中的一级导航
  const mainMenu = permissions.filter((item) => !item.parentPermissionUuid);
  const permissionValueList = mainMenu.map((item) => item.permissionValue);

  let menuList = [];
  moduleMap.forEach((item) => {
    if (permissionValueList.includes(item.permissionValue)) {
      menuList.push(item);
    }
  });
  return menuList;
}

/**
 * 子主应用：权限处理
 * @params subAppType：子应用标识（supplyChain、channelLine、tradeCenter、marketingCenter、businessOnline、customerService）
 * @returns 返回当前用户权限导航列表
 */
function getSubAppList(subAppType = "") {
  const permissions = getPermissions();
  // 权限列表中的一级导航
  const mainMenu = permissions.filter((item) => item.parentPermissionUuid);
  const permissionValueList = mainMenu.map((item) => item.permissionValue);

  console.log("menuData:", menuData);
  console.log("subAppType:", subAppType);
  const subList = menuData[subAppType];
  let subMenuList = [];
  subList.forEach((item, i) => {
    if (permissionValueList.includes(item.permissionValue)) {
      subMenuList.push(item);
    }
    console.log("item===", item, i, subMenuList);
    if (item.children) {
      item.children = handleChildSub(item.children, permissionValueList);
    }
  });
  console.log("subMenuList=====", subMenuList, subList);

  return { subMenuList, subMenuListAll: subList };
}
/**
 * 递归查询
 */
/**
 * 子主应用：三级菜单权限处理
 * @params subAppType：子应用标识（supplyChain、channelLine、tradeCenter、marketingCenter、businessOnline）
 * @returns 返回当前用户权限导航列表
 */
function handleChildSub(children, permissionValueList) {
  let arr = [];
  children.forEach((res, i) => {
    if (permissionValueList.includes(res.permissionValue)) {
      arr.push(res);
    }
    console.log("arr===", arr, i, res);
    if (res?.children) {
      res.children = handleChildSub(res.children, permissionValueList);
    }
    // debugger;
    // if (arr[i - 1]?.children) {
    //   arr[i - 1].children = handleChildSub(res, permissionValueList);
    // }
  });
  return arr;
}
export {
  idaasAuth,
  getAppList,
  getSubAppList,
  hasAuth,
  moduleMapJson,
  getPermissions,
  getAuthFirstRouter,
};
