// microapp微前端框架
import microApp from "@micro-zoe/micro-app";
import router from "@/router";

// 引入打点
import tLog from "@/utils/tlog/tlog";
// import { getSubAppList } from '@/utils/business/auth/auth'
import { getSubAppList } from "@/utils/business/auth/authIdass";
import { goLoginIdaas } from "@/api/callApi";
// 按钮权限
import btnAuth from "@/utils/business/auth/btnAuth.js";
// 脱敏权限
import desensitization from "@/utils/business/auth/desensitization.js";
import commonSensitiveInfo from "@/components/common/commonSensitiveInfo.vue";
import { getPermissions } from "@/utils/business/auth/authIdass";
import { getIdaasToken } from "@/utils/business/login/login";
// 页面级权限
import { getUserAuthResource } from "@/utils/business/auth/utils/goodsGroupAuth/index.js";

// 基础方法
const micro = {
  // 初始化microapp
  start() {
    microApp.start();
  },
  // 向micro中注册全局数据
  registerGlobalData(data) {
    // setGlobalData注册全局方法
    microApp.setGlobalData(data);
  },
  // 向micro的某个子应用中注册数据
  registerData(appName, data) {
    microApp.setData(appName, data);
  },

  // 注册全局方法
  registerStart(globalFunctions) {
    const obj = {};
    for (let key in globalFunctions) {
      obj[key] = globalFunctions[key];
    }
    this.registerGlobalData(obj);
  },
};

// 向所有子应用中，注册全局方法
const globalFunctions = {
  /**
   *
   * @param {*} name
   */
  TLog() {
    return tLog;
  },
  /**
   * 子应用跳转 主应用页面 方法
   * @param {*} name 主应用页面 name
   * eg: 子应用内：micro.jumpOtherApp('/supply-chain#/confirmDelivery')
   */
  parentPush(name) {
    router.push({
      name: name,
    });
  },
  /**
   * 子应用跳转 其他子应用 方法
   * @param {string} path 主应用页面 name
   */
  otherChildPush(path) {
    router.push({
      path: path,
    });
  },

  /**
   * 自应用获取权限列表方法
   * @param {array} array 子应用的侧边栏列表
   */
  getSubAppList,
  goLoginIdaas,
  // 按钮权限
  btnAuth,
  desensitization,
  commonSensitiveInfo,
  getPermissions,
  getIdaasToken,
  getUserAuthResource,
};

export { globalFunctions, micro };
