import {
  getPermissions,
} from "@/utils/business/auth/authIdass";
import store from "@/store";
function handleArr(arr){
  if(Object.prototype.toString.call(arr) === '[object Array]'){
    return arr
  }
  return [arr]
}
export default {
  inserted(el, binding, vnode) {
        /** 从binding中获取dom元素的value值 */
        
        const { value } = binding
        
        /** 存放value值 */
        const btn_permission = handleArr(value);
        /** 拿到所有具备权限按钮的集合，通过传入的按钮id进行匹配 */
        const permissions = getPermissions()
        /** 利用some查询，只要有一个元素满足条件就返回true，全部不满足返回false */
        var hasPermissions = permissions.some(item => {
              return btn_permission.includes(item.permissionValue) 
        })
        
        console.log('binding===按钮权限',btn_permission,hasPermissions)
        /** 传入的按钮id不存在集合里面就移除该节点 */
        if (!hasPermissions) {
              el.parentNode && el.parentNode.removeChild(el)
        }
  }
}
/*
inserted:function (el, binding) {
  //拿到el-button这个按钮标签
  //拿到v-permission自定义指令中{action:'add'}
  const action = binding.value.action		//action就拿到v-permission中的action对应的值.
  //判断,当前的路由所对应的组件中,如何判断用户是否具备action的权限,currentRoute拿到当前组件的路由规则
  console.log(router.currentRoute)
  console.log('ssssss')
  const currentRight = router.currentRoute.meta
  console.log(currentRight)
  let cur_list = [currentRight]
  console.log(cur_list)
  if (cur_list.indexOf(action) === -1) {
    //删除el元素
    el.parentNode.removeChild(el)
    //禁用元素
    // el.disabled = true
    // el.classList.add('is-disabled')
  }

} 
*/