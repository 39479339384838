const marketingCenter = [
  {
    index: "1",
    title: "订单中心",
    icon: "el-icon-receiving",
    permissionValue: "maiketingCenterOrderCenter",
    children: [
      // {
      //   index: "1-1",
      //   name: "OrderManage",
      //   title: "销售订单管理",
      //   permissionValue: "maiketingCenterOrderManage",
      // },
      // {
      //   index: "1-2",
      //   name: "OrderCancel",
      //   title: "销售订单取消",
      //   permissionValue: "maiketingCenterOrderCancel",
      // },
      // {
      //   index: "1-3",
      //   name: "Photo",
      //   title: "宴席审批",
      // },
      // {
      //   index: "1-4",
      //   name: "BanquetApproval",
      //   title: "旧宴席审批",
      //   permissionValue: "maiketingCenterBanquetApproval",
      // },
      {
        index: "1-5",
        name: "BanquetOrder",
        title: "宴席订单",
        permissionValue: "maiketingCenterBanquetAOrder",
      },
      {
        index: "1-6",
        name: "RaffleFreeBanquetOrder",
        title: "抽免单宴席订单",
        permissionValue: "maiketingCenterRaffleFreeBanquetOrder",
      },
      // {
      //   index: "1-5",
      //   name: "SureOrder",
      //   title: "销售订单确认",
      //   permissionValue: "maiketingCenterSureOrder",
      // },
      // {
      //   index: "1-6",
      //   name: "DouyinTasteOrder",
      //   title: "抖音品鉴",
      //   permissionValue: "maiketingCenterDouyinTaste",
      // },
      {
        index: "1-7",
        name: "consumerOrder",
        title: "消费者订单查询",
        permissionValue: "marketingCenterCusumerOrders",
      },
      // {
      //   index: "1-8",
      //   name: "giftcard",
      //   title: "礼品卡管理",
      //   permissionValue: "marketingCenterGiftCard",
      // },
      // {
      //   index: "1-9",
      //   name: "giftcardOrder",
      //   title: "礼品卡订单管理",
      //   permissionValue: "marketingCenterGiftCardOrder",
      // },
    ],
  },
  {
    index: "2",
    title: "用户管理",
    icon: "el-icon-s-check",
    permissionValue: "maiketingCenterUserManager",
    children: [
      {
        index: "2-1",
        name: "Consumers",
        title: "消费者管理",
        permissionValue: "maiketingCenterConsumers",
      },
      {
        index: "2-2",
        name: "fuCardConsumers",
        title: "集福卡用户管理",
        permissionValue: "fuCardConsumers",
      },
    ],
  },
  {
    index: "3",
    title: "渠道活动管理",
    icon: "el-icon-s-management",
    permissionValue: "maiketingCenterActiveManager",
    children: [
      {
        index: "3-1",
        name: "banquetActive",
        title: "宴席活动",
        permissionValue: "maiketingCenterBanquetActive",
      },
    ],
  },
  {
    index: "4",
    title: "活动审核",
    icon: "el-icon-s-check",
    permissionValue: "maiketingCenterMpManager",
    children: [
      {
        index: "4-1",
        name: "miancheck",
        title: "交杯审核",
        permissionValue: "maiketingCenterMpManagerCheck",
      },
      {
        index: "4-2",
        name: "quanlist",
        title: "券绑定",
        permissionValue: "maiketingCenterMpManagerQun",
      },
      {
        index: "4-3",
        name: "cateringbase",
        title: "品鉴会请进来核销",
        permissionValue: "cateringBaseTastingWriteOff",
      },

      {
        index: "4-9",
        name: "chouCheck",
        title: "抽免单宴席订单审核",
        permissionValue: "maiketingCenterMpPpyjChouCheck",
      },
      {
        index: "4-7",
        name: "cupCheck",
        title: "瓶瓶有奖宴席审核",
        permissionValue: "maiketingCenterMpPpyjCheck",
      },
      {
        index: "4-4",
        name: "banquetsAndCupCheck",
        title: "交杯开盖审核",
        permissionValue: "maiketingCenterMpManagerBanquetsAndCupCheck",
      },

      {
        index: "1-4",
        name: "BanquetApproval",
        title: "9瓶送3斤宴席审核",
        permissionValue: "maiketingCenterBanquetApproval",
      },

      {
        index: "4-5",
        name: "makeCupWhitelist",
        title: "交杯黑名单",
        permissionValue: "maiketingCenterMpManagermakeCupWhitelist",
      },
      {
        index: "4-10",
        name: "eCommerceList",
        title: "电商宴席订单审核",
        permissionValue: "maiketingCenterECommerceList",
      },
    ],
  },
  {
    index: "5",
    title: "直播商品管理",
    icon: "el-icon-s-check",
    permissionValue: "maiketingCenterLiveManager",
    children: [
      {
        index: "5-1",
        name: "liveGoodsManage",
        title: "直播商品管理",
        permissionValue: "maiketingCenterLiveManagerGoodsManage",
      },
    ],
  },
  {
    index: "6",
    title: "内容管理",
    icon: "el-icon-s-platform",
    permissionValue: "maiketingCenterMediaManage",
    children: [
      {
        index: "6-1",
        name: "mediaList",
        title: "内容管理",
        permissionValue: "maiketingCenterMediaList",
      },
      {
        index: "6-2",
        name: "mediaType",
        title: "类目管理",
        permissionValue: "maiketingCenterMediaType",
      },
    ],
  },

  // {
  //   index: "3",
  //   title: "招商和渠道活动",
  //   icon: "el-icon-s-order",
  //   permissionValue: "maiketingCenterActivity",
  //   children: [
  //     {
  //       index: "3-1",
  //       name: "ApplyManage",
  //       title: "品鉴管理",
  //     },
  //     {
  //       index: "3-2",
  //       name: "WineReplenishment",
  //       title: "品鉴酒补发记录",
  //     },
  //     {
  //       index: "3-3",
  //       name: "Smallware",
  //       title: "小品会费用",
  //     },
  //   ],
  // },
  // {
  //   index: "7",
  //   title: "集印花",
  //   icon: "el-icon-s-shop",
  //   permissionValue: "marketingCenterCollectPrinting",
  //   children: [
  //     {
  //       index: "7-1",
  //       title: "用户信息管理",
  //       name: "collectPrintingUserManage",
  //       permissionValue: "marketingCenterCollectPrintingUser",
  //     },
  //   ],
  // },
  {
    index: "8",
    title: "活动配置",
    icon: "el-icon-setting",
    permissionValue: "maiketingCenterActConfig",
    children: [
      {
        index: "8-1",
        name: "dayTurntable",
        title: "B端定向发奖",
        permissionValue: "maiketingCenterActConfigDayTurntable",
      },
      {
        index: "8-2",
        name: "bottlesLottery",
        title: "瓶瓶有奖定向发奖",
        permissionValue: "maiketingCenterActConfigBottles",
      },
    ],
  },
  {
    index: "9",
    title: "低代码（三期）",
    icon: "el-icon-eleme",
    permissionValue: "marketingCenterLowCode",
    children: [
      {
        index: "9-1",
        title: "资源列表",
        name: "marketingCenterLowCodeList",
        permissionValue: "marketingCenterLowCodeList",
      },
    ],
  },
  // {
  //   index: "10",
  //   title: "海报推广",
  //   icon: "el-icon-eleme",
  //   permissionValue: "marketingCenterPosterPromotion",
  //   children: [
  //     {
  //       index: "10-1",
  //       title: "海报推广配置",
  //       name: "posterPromotionConfig",
  //       permissionValue: "marketingCenterPosterPromotionConfig",
  //     },
  //   ],
  // },
];

export { marketingCenter };
