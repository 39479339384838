/*
 * @Author: Mr.Hu
 * @Date: 2022-06-29 10:13:55
 * @Description: 页面级别权限验证,传入页面需要验证的权限值,返回该登陆人拥有的权限值
 */
import { getPermissions } from "@/utils/business/auth/authIdass";
/**
 * 将传入的参数(字符串或数组)转化成数组
 * @param {Array} 字符串或数组
 * @returns
 */
const handleArr = (arr) => {
  if (Object.prototype.toString.call(arr) === "[object Array]") {
    return arr;
  }
  return [arr];
};
/**
 * 过滤账户拥有的权限资源值
 * @param {*} permissionList
 */
export const getUserAuthResource = (permissionList) => {
  /** 拿到所有具备权限的集合，通过传入的权限值进行匹配 */
  permissionList = handleArr(permissionList);
  const accountPermissions = getPermissions();
  const resultPermission = [];
  accountPermissions.forEach(({ permissionValue }) => {
    if (permissionList.includes(permissionValue)) {
      resultPermission.push(permissionValue);
    }
  });
  return resultPermission;
};
