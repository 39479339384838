import { callApi, callApiFloor } from "@/api/callApi";
import geteWay from "@/config/gateway.config";

// 获取各个接口地址
import {
  accessToken,
  userInfoByToken,
  logout,
  queryWaitAuditCount,
} from "@/api/apiList";

// 静态全局变量
import { staticData } from "@/config/base.config";
import { getIdaasToken } from "@/utils/business/login/login";

const axiosBff = callApi(geteWay.BFF);
const axios_gateWay = callApiFloor("GATEWAY_URL");

// 登录接口
export function loginApi(data) {
  return axiosBff({
    url: accessToken,
    method: "get",
    data: data,
    headers: {
      appid: staticData.appid,
    },
    config: {
      noIntercept: true,
    },
  });
}

export function getUserGuid(accessToken) {
  return axiosBff({
    url: userInfoByToken,
    method: "get",
    headers: {
      appid: staticData.appid,
      accessToken,
    },
    config: {
      noIntercept: true,
    },
  });
}

// 退出登录接口
export function logOutApi(data) {
  return axiosBff({
    url: logout,
    method: "post",
    data: data,
    headers: {
      accesstoken: getIdaasToken(),
      appid: staticData.appid,
    },
    config: {
      noIntercept: true,
    },
  });
}
// 消息接口
export function queryWaitAuditCountApi(data) {
  return axios_gateWay({
    url: queryWaitAuditCount,
    method: "post",
    data: data,
    headers: {
      xtoken: getIdaasToken(),
    },
  });
}
