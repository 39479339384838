/* eslint-disable */

import Cookies from "js-cookie"; // 第三方cookie插件：文档地址：https://github.com/js-cookie/js-cookie
import { Message } from "@xtdev/xt-element-ui";

const projectName = 'thp' // 项目cookie名称前缀

//返回传递给他的任意对象的类(返回：array、object、number、string)
function typeOf(o) {
  if (o === null) return "Null";
  if (o === undefined) return "Undefined";

  return Object.prototype.toString.call(o).slice(8, -1).toLowerCase();
}

// 坐标像素转换(count:设计图(750)上的px值)，设计图的px => 真实dom中的px
function changePx(count) {
  const winWidth = window.screen.width;
  const ratio = winWidth / 750; // 比例
  return count * ratio;
}

// 防抖中心思想：如果频繁操作，在setTimeout未执行前就clearTimeout，则当前setTimeout就不会执行。
function debounce(method, delay) {
  let timer = null;
  return function () {
    let self = this,
      args = arguments;

    timer && clearTimeout(timer);
    timer = setTimeout(function () {
      method.apply(self, args);
    }, delay);
  };
}

// 节流：如果频繁操作，在setTimeout未执行前就clearTimeout，则当前setTimeout就不会执行。
function throttle(method, duration) {
  let timer = null;
  let startTime = new Date();

  return function () {
    let endTime = new Date();
    let self = this;
    let args = arguments;

    clearTimeout(timer);
    if (endTime - startTime >= duration) {
      method.apply(self, args);
      startTime = endTime;
    } else {
      timer = setTimeout(function () {
        method.apply(self, args);
      }, duration);
    }
  };
}

/* H5设置本地存储(示例如下)
  1. h5.setStorage('tc_abc', '哈哈哈')
  2. h5.setStorage('tc_abc', {a:1, b:2})
 */
function setStorage(key, value) {
  const v = typeOf(value) === "string" ? value : JSON.stringify(value) || "";
  localStorage.setItem(key, v);
}

/* H5删除本地存储(示例如下)
  h5.removeStorage('20191111_game_v1')
 */
function removeStorage(key) {
  localStorage.getItem(key) && localStorage.removeItem(key);
}

/* H5获取本地存储(示例如下)
  1. let data = h5.getStorage('20191111_game_v1')
  2. let data = h5.getStorage('20191111_game_v1', 'deviceId')
  console.log(data)
 */
function getStorage(key, subKey) {
  let res = "";
  const stringLocal = localStorage.getItem(key) || "";
  let parseLocal = "";

  try {
    parseLocal = JSON.parse(stringLocal);
  } catch (error) {
    parseLocal = stringLocal;
  }

  // 区分是否有subKey
  if (!subKey) {
    res = parseLocal;
  } else {
    if (typeOf(parseLocal) === "object") {
      res = parseLocal[subKey] || "";
    }
  }

  return res;
}

// 判断是否是pc端
function isPC() {
  let userAgentInfo = navigator.userAgent;
  let Agents = new Array(
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod"
  );
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

//设置页面rem
function setRem(baseVal = 10) {
  const win = window;
  const doc = document;

  const docEl = doc.documentElement;
  const resizeEvt = "orientationchange" in win ? "orientationchange" : "resize";
  const recalc = () => {
    let clientWidth = docEl.clientWidth;
    if (!clientWidth) return;
    docEl.style.fontSize = clientWidth / baseVal + "px";
  };
  recalc();
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener("DOMContentLoaded", recalc, false);
}

// 时间转换
function formDater(time) {
  let now = new Date(time);
  let y = now.getFullYear();
  let M = now.getMonth() + 1;
  let d = now.getDate();
  let h = now.getHours();
  let m = now.getMinutes();
  let s = now.getSeconds();
  return (
    y +
    "-" +
    addZero(M) +
    "-" +
    addZero(d) +
    " " +
    addZero(h) +
    ":" +
    addZero(m) +
    ":" +
    addZero(s)
  );
}
function addZero(m) {
  return m < 10 ? "0" + m : m;
}
/**
 * 打开 当前地址的新窗口
 */
function openNewPage(path) {
  window.open(window.origin + window.pathname + "#" + path);
}


/**
 * 对象转数组，传入对象
 * @param {*} obj 
 * @returns array
 */
function objToArray(obj) {
  let array = [];
  for (let key in obj) {
    obj[key].key = key;
    array.push(obj[key]);
  }
  return array;
}

/**
 * 数组转对象，传入数组
 * @param {*} array
 * @returns {}
 */
function arrayToObj(array, keyStr = '') {
  let obj = {};
  array.map((item, index) => {
    const key = keyStr ? item[keyStr] : index
    obj[key] = item
  })
  return obj;
}

// 第三方cookie插件：首页地址：https://github.com/component/cookie#readme
/* 设置cookie
  用法一：
  setCookie('topay_cli_manage_name', '123')
  用法二：
  var in15Minutes = new Date(new Date().getTime() + 15 * 60 * 1000);
  setCookie('topay_cli_manage_name', '123', {
    path: /about,
    expires: in15Minutes, // 过期时间（毫秒）
  })
*/
function setCookie(key, value, config = {}) {
  if (!new RegExp(`^${projectName}_.*`, "g").test(key)) {
    throw new Error(
      `${projectName}_项目设置cookie的key值最必须以"${projectName}_"开头，请修改!`
    );
  }

  const defaultConfig = {
    path: "/",
  };
  Object.assign(defaultConfig, config);
  Cookies.set(key, value, defaultConfig);
}

/*
  用法一：
  removeCookie('topay_cli_manage_name')
  用法二：
  removeCookie('topay_cli_manage_name', {
    path: /about,
  })
*/
function removeCookie(key, config = {}) {
  if (!new RegExp(`^${projectName}_.*`, "g").test(key)) {
    throw new Error(
      `${projectName}_项目设置cookie的key值最必须以"${projectName}_"开头，请修改!`
    );
  }

  const defaultConfig = {
    path: "/",
  };
  Object.assign(defaultConfig, config);
  Cookies.remove(key, defaultConfig); // removed!
}

/* 获取cookie
  用法一：
  getCookie('cookieName')
  用法二：
  getCookie('cookieName', {
    path: /about,
  })
*/
function getCookie(key, config = {}) {
  const defaultConfig = {
    path: "/",
  };
  Object.assign(defaultConfig, config);
  return Cookies.get(key, defaultConfig);
}

//  消息提示框
function showTip(msg, type) {
  Message({
    message: msg,
    type: type,
    customClass: 'global-messageZindex'
  });
}

export {
  typeOf,
  changePx,
  debounce,
  throttle,
  setStorage,
  getStorage,
  removeStorage,
  isPC,
  setRem,
  formDater,
  openNewPage,
  objToArray,
  arrayToObj,
  setCookie,
  getCookie,
  removeCookie,
  showTip,
};
