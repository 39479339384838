/*
 * @Author: Mr.Hu
 * @Date: 2022-06-14 17:16:33
 * @Description:
 */
const dataCenter = [
  // {
  //   index: "1",
  //   title: "商品中心",
  //   icon: "el-icon-receiving",
  //   permissionValue: "dataCenterTable",
  //   children: [
  //     {
  //       index: "1-1",
  //       name: "GoodsGroup",
  //       title: "商品分组",
  //       permissionValue: "dataCenterGroupTable",
  //     },
  //     {
  //       index: "1-2",
  //       name: "GoodsMap",
  //       title: "商品映射",
  //       permissionValue: "dataCenterGoodsMapTable",
  //     },
  //   ],
  // },
];

export { dataCenter };
