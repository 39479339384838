import {
  loginApi,
  getSaleAreaApi,
  getPartIdApi,
  getUserDeptsApi,
} from "../service/login";
import store from "@/store";

// 老tc逻辑，兼容旧页面不出问题 ------------------
const mergeOld = {
  bizRoleCodes: [],

  /**
   *  根据登录返回的id查找part_id getPartId
   */
  async getPartId(id) {
    console.log("进入getPartId");

    const json = [id];
    const res = await getPartIdApi(json);

    if (res.errCode === 0) {
      // isSys === 1 表示当前用户是管理员
      if (res.retdata[0] && res.retdata[0].isSys === 1) {
        // 管理员 可以看所有菜单
        localStorage.setItem("isMANAGER", 2);
        store.commit("SET_ISMANAGER", 2);
        // this.goDefault();
      } else if (res.retdata[0] && res.retdata[0].mobile) {
        this.getUserRole(res.retdata[0].mobile);
      } else {
        // this.goDefault();
      }
    } else {
      // this.goDefault();
    }
    if (res.retdata[0] && res.retdata[0].mobile) {
      // 资源轮转3期需要userid
      this.getUserId(res.retdata[0].mobile);
    }
  },
  /**
   * 获取用户userId
   */
  async getUserId(mobile) {
    const json = {
      mobile,
    };
    const res = await getUserDeptsApi(json);
    console.log(res);
    let data = { userId: "-1", userTag: "1", projectVersion: "1.0.1" };

    let userd = { userId: "-1", name: "", mobile: "" };
    if (res.retdata.user) {
      // data.userId = res.retdata.user.id || '';
      data.userId = res.retdata.user.tuserPartId || "-1";
      data.userName = encodeURIComponent(res.retdata.user.name);
      localStorage.setItem("x-login-session", JSON.stringify(data));
      userd.userId = res.retdata.user.id || "-1";
      userd.name = encodeURIComponent(res.retdata.user.name);
      userd.mobile = mobile;
      localStorage.setItem("wow-login-user", JSON.stringify(userd));
    } else {
      localStorage.setItem("x-login-session", JSON.stringify(data));
      localStorage.setItem("wow-login-user", JSON.stringify(userd));
    }
  },
  /**
   *  根据用户id查询用户角色
   */
  async getUserRole(mobile) {
    const json = {
      mobile,
    };
    const res = await getUserDeptsApi(json);
    if (res.errCode === 0 && res.retdata && res.retdata.user) {
      // 部门列表
      const depts = res.retdata.depts;
      // const deptLevels = [];
      store.commit("SET_DEPTOPTIONS", depts);

      // 角色列表
      const roleList = res.retdata.roles;
      localStorage.setItem("deptList", JSON.stringify(depts));
      // 角色名集合
      let roleNameList = [];
      if (res.retdata.user && res.retdata.user.station) {
        localStorage.setItem("userStation", res.retdata.user.station);
      }
      if (res.retdata.user && res.retdata.user.tuserPartId) {
        localStorage.setItem("tuserPartId", res.retdata.user.tuserPartId);
      } else {
        localStorage.setItem("tuserPartId", "");
      }
      localStorage.setItem("SALE_OPERATION_BP", false);
      // 判断是否是业务区
      this.getSaleArea(res.retdata.user.id).then(() => {
        // 获取bizRoleCode 2022-2-24菜单权限需求
        if (roleList && roleList.length) {
          roleList.forEach((item) => {
            if (item.name === "团购销售总监") {
              localStorage.setItem("isDirector", 1);
              this.isDirector = 1;
            }
            if (item.bizRoleCode) {
              this.bizRoleCodes.push(item.bizRoleCode);
            } else {
              this.bizRoleCodes.push(`${item.bizRoleCode}`);
            }
            // 如果包含 内勤角色 则存入缓存 item.bizRoleCode === "SECRETARY"
            if (item.bizRoleCode === "SECRETARY") {
              localStorage.setItem("isSecretary", true);
            }

            // 如果包含三个业务中台角色 存入缓存
            if (
              item.bizRoleCode === "SALES_OPERATION_MANAGER" ||
              item.bizRoleCode === "SALES_OPERATIONS" ||
              item.bizRoleCode === "STRATEGY_OPERATION"
            ) {
              localStorage.setItem("busnCenter", true);
            } else {
              localStorage.setItem("busnCenter", false);
            }
            // 如果包含 BP角色 则存入缓存 item.bizRoleCode === "SECRETARY"
            if (item.bizRoleCode === "SALE_OPERATION_BP") {
              localStorage.setItem("SALE_OPERATION_BP", ture);
            }
          });
          roleNameList = roleList.map((item) => {
            return item.name;
          });
          localStorage.setItem("roleNameList", JSON.stringify(roleNameList));

          // 判断是否是超级管理员
          if (
            roleNameList.includes("主管") &&
            (depts[0].name === "销售运营组" ||
              depts[0].name === "业务中台" ||
              depts[0].name === "潭酒事业部") &&
            localStorage.getItem("userStation") === "销售运营经理"
          ) {
            localStorage.setItem("deptLevel", "admin");
            store.commit("SET_DEPTLEVEL", "admin");
          } else {
            localStorage.setItem("deptLevel", depts[0].level);
            store.commit("SET_DEPTLEVEL", depts[0].level);
          }

          localStorage.setItem("deptId", JSON.stringify(depts[0].id));
          localStorage.setItem("deptName", depts[0].name);
          // 保存是否是部门负责人
          localStorage.setItem("isOwner", JSON.stringify(depts[0].isOwner));
          // this.getLevelByDepId(dept[0].id);
          store.commit("SET_DEPTLIST", depts);
          store.commit("SET_DEPTNAME", depts[0].name);
          store.commit("SET_DEPTID", depts[0].id);
          localStorage.setItem("businessArea", this.businessArea);
          // 如果是业务区的人员 进行大区总和省总判断
          // 如果包含大区总和省总的角色，则只开放目标管理菜单
          if (
            this.bizRoleCodes.includes("AREA_MANAGER") ||
            this.bizRoleCodes.includes("PROVINCE_MANAGER")
          ) {
            localStorage.setItem("isMANAGER", 0);
            store.commit("SET_ISMANAGER", 0);
            // this.$router.push({ path: "/indexLibrary" });
            // this.goDefault();
          } else {
            // 其他表示除开大区总和省总的其他无权限角色 不开放菜单
            localStorage.setItem("isMANAGER", 1);
            store.commit("SET_ISMANAGER", 1);
            // this.goDefault();
          }
        } else if (this.businessArea) {
          // this.goDefault()
          // this.$message.error("暂无权限哦~");
        } else {
          localStorage.setItem("isMANAGER", 2);
          store.commit("SET_ISMANAGER", 2);
          // this.goDefault();
        }
      });
    } else {
      // this.goDefault()
      // this.$message.error("暂无权限哦~");
    }
  },
  /**
   *  根据用户id判断用户是不是业务区的
   */
  getSaleArea(id) {
    return new Promise(async (resolve, reject) => {
      const json = {
        userId: id,
      };
      const res = await getSaleAreaApi(json);
      if (res.errCode === 0) {
        this.businessArea = res.retdata;
        resolve();
        if (store.state.isManager === 0 || this.isDirector === 1) {
          // this.$router.push({ path: "/indexLibrary" });
          // this.goDefault();
        } else {
          // this.goDefault();
        }
      } else {
        // this.$message.error("暂无权限哦~");
      }
    });
  },
};
// 老tc逻辑，兼容旧页面不出问题 ------------------

export default mergeOld;
