const login = "/user/login"; // 登录
const getSaleArea = "/user-center-final/user/isSaleAreaUser"; // 判断是不是业务区的人员
const getPartId = "/user-center-final/user/getUserByTUserId"; //通过t_user_id查询t_user_part_id
const getUserDepts = "/user-center-final/user/getUserDetailByMobile"; // 根据用户id获取用户角色列表
const msgPush = "/msgPush/listByCondition"; // 消息推送
const accessToken = "/auth/accessToken"; // 登录接口
const userInfoByToken = "/user/userinfo"; // 登录接口
const logout = "/auth/logout"; // 退登接口
const menu = "/user/menu"; // 权限接口
// 消息提醒
const queryWaitAuditCount = "audit/queryWaitAuditCount";

export {
  login,
  getSaleArea,
  getPartId,
  getUserDepts,
  msgPush,
  accessToken,
  logout,
  menu,
  userInfoByToken,
  queryWaitAuditCount,
};
