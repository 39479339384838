/* eslint-disable */

/*
  基础信息配置文件
 */
const isProd = process.env.VUE_APP_ENV === `production`;
// 全局错误提示
const errMsg = '网络错误，请稍后重试~'
const appId = isProd ? 354269 : 413120; // 火山引擎埋点，appid
const product = isProd ? `taohuapo` : `taohuapo_test`; // 火山引擎埋点，产品名称

// 需要的第三方插件url集合
const sourceJs = {
  echarts: {
    name: 'echarts',
    global: 'tc-Echarts', // 第三方插件的全局变量名，用于避免重复加载
    ver: '0.0.1',
    isLoad: true,
    cache: false,
    src: './plugins/echarts/echarts4.6.0.min.js'
  },
  moment: {
    name: 'moment',
    global: 'moment', // 第三方插件的全局变量名，用于避免重复加载
    ver: '0.0.1',
    isLoad: true,
    cache: false,
    src: './plugins/moment/moment.js'
  },
}
// 需要的第三方插件url集合,css资源结构
const sourceCss = {
  // element: { // 文档：http://momentjs.cn/
  //   name: 'momentCss',
  //   global: 'momentCss', // 第三方插件的全局变量名，用于避免重复加载
  //   ver: '0.0.1',
  //   isLoad: true,
  //   cache: false,
  //   src: 'https://unpkg.com/element-ui@2.14.1/lib/theme-chalk/index.css'
  //   // src: `${gateWay.pluginsHost}/plugins/moment/moment.min.js`,
  // },
}


// 测试环境登录地址
const clientIdTest = `05bed4d4cc73dbf8e54902e4005ee193gjX0h3snFYs`
const redirectUrlTest = `https://ncenter.test.tanjiu.cn/main`
const loginUrlTest = `http://47.97.83.98/oauth/authorize?response_type=code&scope=read&client_id=${clientIdTest}&redirect_uri=${encodeURIComponent(redirectUrlTest)}`
const logoutUrlTest = `http://47.97.83.98/oauth/authorize?response_type=code&scope=read&client_id=${clientIdTest}&redirect_uri=${encodeURIComponent(redirectUrlTest)}`
// `http://47.97.83.98/public/sp/slo/idaasplugin_oauth23`
// 线上登录地址
const clientId = `d80cbd3863d85238240afd3c41fedecfEY2uKRm50NY`
const redirectUrl = `https://tc.tanjiu.cn/main`
const loginUrl = `https://idaas.tanjiu.cn/oauth/authorize?response_type=code&scope=read&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUrl)}`
const logoutUrl =`https://idaas.tanjiu.cn/oauth/authorize?response_type=code&scope=read&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUrl)}`
//  `https://idaas.tanjiu.cn/public/sp/slo/xtjyplugin_oauth21`

const staticDev = {
  appid: `idaasplugin_oauth23`, // 桃花坡固定appid，idaas登录使用
  // idaasLoginUrl: `http://47.97.83.98/frontend/login?state=feawf`, // 登录页地址
  // 登录页地址
  idaasLoginUrl: loginUrlTest,
  idaasLogoutUrl: logoutUrlTest
}

const staticTest = {
  appid: `idaasplugin_oauth23`, // 桃花坡固定appid，idaas登录使用
  // idaasLoginUrl: `http://47.97.83.98/frontend/login`, // 登录页地址
  // 登录页地址
  idaasLoginUrl: loginUrlTest,
  idaasLogoutUrl: logoutUrlTest
}
const staticProd = {
  appid: `xtjyplugin_oauth21`, // 桃花坡固定appid，idaas登录使用
  // idaasLoginUrl: `https://idaas.tanjiu.cn/frontend/login`, // 登录页地址
  // 登录页地址
  idaasLoginUrl: loginUrl,
  idaasLogoutUrl: logoutUrl
}

// 区分环境选择静态资源地址
const env = process.env.VUE_APP_ENV;
let staticData = "";
if (env === "production") {
  staticData = staticProd;
} else if (env === "test") {
  staticData = staticTest;
} else {
  staticData = staticDev;
}

export {
  errMsg,
  sourceJs,
  sourceCss,
  staticData,
  appId,
  product
}
