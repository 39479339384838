import hasBtn from './utils/btnAuth/btnMethod.js'

const install = function (Vue) {
      Vue.directive('hasBtn', hasBtn)
}
if (window.Vue) {
      window['hasBtn'] = hasBtn
      Vue.use(install); // eslint-disable-line
}
export default install
