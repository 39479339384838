const mainRouter = [
  // {
  //   path: "/",
  //   defaultRouterName: "Dashboard",
  //   title: "首页",
  //   permissionValue: "mainSupplyChain",
  // },
  {
    path: "/supply-chain",
    defaultRouterName: "supply-chain",
    title: "供应链",
    permissionValue: "mainSupplyChain",
  },
  {
    path: "/channel-line",
    defaultRouterName: "channel-line",
    title: "渠道业务线",
    permissionValue: "mainChannelLine",
  },
  {
    path: "/trade-center",
    defaultRouterName: "trade-center",
    title: "交易中心",
    permissionValue: "mainTradeCenter",
  },
  {
    path: "/marketing-center",
    defaultRouterName: "marketing-center",
    title: "营销中心",
    permissionValue: "mainMarketingCenter",
  },
  {
    path: "/business-online",
    defaultRouterName: "business-online",
    title: "电商业务",
    permissionValue: "businessOnline",
  },
  {
    path: "/customer-service",
    defaultRouterName: "customer-service",
    title: "客服中心",
    permissionValue: "customerService",
  },
  {
    path: "/data-center",
    defaultRouterName: "data-center",
    title: "数据中心",
    permissionValue: "mainDataCenter",
  },
];

export { mainRouter };
