import { mainRouter } from "./mainRouter";
import { channelLine } from "./channelLine";
import { supplyChain } from "./supplyChain";
import { tradeCenter } from "./tradeCenter";
import { dataCenter } from "./dataCenter";
import { marketingCenter } from "./marketingCenter";
import { businessOnline } from "./businessOnline";
import { customerService } from "./customerService";

export default {
  mainRouter,
  channelLine,
  supplyChain,
  tradeCenter,
  dataCenter,
  marketingCenter,
  businessOnline,
  customerService,
};
