import { checkToken } from "@/api/callApi.js";
import store from "@/store";

import {
  getAuthFirstRouter,
  moduleMapJson,
  getPermissions,
} from "@/utils/business/auth/authIdass";

// 所有页面的路由守卫，在页面载入前的校验
function check(to, router) {
  let checkhMark = true;

  // 不校验token页面：黑名单
  // const blackList = ["Login"];
  // // 校验当前页面routeName（非login页面执行token验证）
  // if (!blackList.includes(store.state.currRouteName)) {
  //   const hasToken = checkToken();
  //   if (!hasToken) return false;
  // }

  // 当前页不在权限内，自动跳转到拥有权限的第一个menu
  const whiteList = ["PushManage", 'Login'];
  if (!isInAuthRouters(to)) {
    // 白名单，直接跳过权限检测，所有人都有权限进入(例如：消息中心页面)
    if (whiteList.includes(to.name)) {
      checkhMark = true;
    } else {
      // 当前权限内第一个一级菜单的router
      const authRouter = getAuthFirstRouter();
      router.replace({
        path: authRouter.path,
      });
      checkhMark = false;
      console.log(
        `当前页面路由不在当前用户的权限列表内，已自动跳转第一个有权限的菜单路由：`,
        authRouter
      );
    }
  }

  return checkhMark;
}

// 当前页是否在权限内
function isInAuthRouters(to) {
  const mainRouters = moduleMapJson();
  let targetRouter = mainRouters.filter(
    (item) => item.defaultRouterName === to.name
  );
  const permissionValue =
    targetRouter[0] && targetRouter[0].permissionValue
      ? targetRouter[0].permissionValue
      : "";

  const authRouters = getPermissions();
  let targetAuthRouter = authRouters.filter(
    (item) => item.permissionValue === permissionValue
  );

  let result = targetAuthRouter.length ? true : false;
  return result;
}

export { check };
