<template>
  <!-- <div class="common-sensitive-info"> -->
  <div
    v-if="!!showData"
    class="common-sensitive-info"
    :style="{ display: isInline ? 'inline-block' : 'flex' }"
  >
    <div class="box">
      <div class="info">{{ showData }}</div>
      <div class="view">
        <div @click="view" v-show="viewStatus">
          <img
            src="https://img.tanjiu.cn/home/bHzZWXt2bkjzc83KeCnb5RbdY4ks3kBH.png"
          />
        </div>
        <div @click="view" v-show="!viewStatus">
          <img
            src="https://img.tanjiu.cn/home/NRemws2CkGkC6BMnysiMzdmjRR7Y632M.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gateWayConfig from "@/config/gateway.config";

import { callApi } from "@/api/callApi";
const baseApi = gateWayConfig["BFF"];
const axios_thp = callApi(baseApi);
const dayjs = require("dayjs");
export default {
  name: "commonSensitiveInfo",
  props: {
    // 数据类型，若设置了customRule，则该属性失效，组件支持几种默认类型，电话:phone,银行卡号bankAccount,身份证号:idCard
    infoType: {
      type: String,
      default: "phone",
    },
    // 完整值，必传
    completeData: {
      type: String,
      required: true,
      default: "",
    },
    // 自定义脱敏数据，若有值，则会展示该值作为脱敏展示
    partialData: {
      type: String,
      default: "",
    },
    // 是否开启内联模式，若为false，则会视为块元素
    isInline: {
      type: Boolean,
      default: false,
    },
    // 自定义隐藏规则，传入数组，如[1,2]代表隐藏前一位和后两位
    customRule: {
      type: Array,
    },
    // 模块描述，描述该脱敏信息位置，如网点管理-列表-电话
    moduleDesc: {
      type: String,
    },
  },
  data() {
    return {
      nowHeight: null,
      show: true,
      viewStatus: false,
      showData: "",
    };
  },
  watch: {},
  created() {
    if (this.partialData) {
      this.showData = this.partialData;
    } else {
      this.transformInfo();
    }
  },
  beforeDestroy() {},
  methods: {
    view: function () {
      if (this.viewStatus) {
        // 隐藏
        if (this.partialData) {
          this.showData = this.partialData;
          return;
        }
        this.transformInfo();
      } else {
        // 展示
        this.showData = this.completeData;
        const userInfo = JSON.parse(localStorage.getItem("thp_userInfo"));
        const { id, userName } = userInfo;
        const _this = this;
        axios_thp({
          url: "/log/log",
          method: "post",
          data: {
            operatorId: `${id}`,
            operatorName: userName,
            operationTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            moduleDesc: _this.moduleDesc,
            realValue: _this.completeData,
          },
        }).then(() => {});
      }
      this.viewStatus = !this.viewStatus;
    },
    transformInfo: function () {
      let startLen = 0;
      let endLen = 0;
      if (this.customRule && Array.isArray(this.customRule)) {
        this.showData = this.desensitizedCommon(
          this.completeData,
          this.customRule[0] || 0,
          this.customRule[1] || 0
        );
        return;
      }
      switch (this.infoType) {
        case "phone":
          startLen = 3;
          endLen = 4;
          break;
        case "bankAccount":
          startLen = 4;
          endLen = 4;
          break;
        case "idCard":
          startLen = 4;
          endLen = 2;
          break;
        default:
          startLen = 0;
          endLen = 0;
          return;
      }
      this.showData = this.desensitizedCommon(
        this.completeData,
        startLen,
        endLen
      );
    },
    desensitizedCommon(str, begin, end) {
      if (!str || begin + end >= str.length) {
        return "";
      }

      let leftStr = str.substring(0, begin);
      let rightStr = str.substring(str.length - end, str.length);

      let strCon = "";
      for (let i = 0; i < str.length - end - begin; i++) {
        strCon += "*";
      }
      return leftStr + strCon + rightStr;
    },
  },
};
</script>
<style lang="scss" scope>
.common-sensitive-info {
  .box {
    display: flex;
    justify-content: center;
    line-height: 20px;
  }
  .view {
    cursor: pointer;
    margin-left: 10px;
    img {
      width: 20px;
    }
    > div {
      width: 40px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
