// 接入火山引擎
import TLog from "@xtdev/tlog";
const env = process.env.VUE_APP_ENV; // 当前构建的环境变量(development、test、production)
const sensor_domain = env === "production" ? 'https://sensor.tanjiu.cn/v1/event' : 'https://sensor.test.tanjiu.cn/v1/event';
const tLog = {
  tlog: null,
  init(app_id) {
    this.tlog = new TLog({
      app_id: app_id, // app_id
      channel_domain: sensor_domain, // 自定义域名
      report_channel: "cn", // 上报通道
      auto_report: true, // 是否开启上报
      enable_ab_test: false, // 是否开启AB
      enable_profile: true, // 是否开启用户文件
      show_log: true, // 是否显示日志
    });
  },
  // 注册产品
  register(pruduct,guid) {
    if (this.tlog && this.tlog.register) {
      this.tlog.register({ product: pruduct,user_unique_id:guid });
      console.log("注册“taohuapo”火山引擎成功");
    }
  },
  /**
   * 打点
   * @param {} event 事件
   * @param {} track
   * @param {} data  打点数据
   */
  mytLog(event, track, data) {
    if (this.tlog && this.tlog.track) {
      this.tlog.track(event, track, data);
      console.log(
        `火山引擎事件打点成功；event:${event}；track:${track}；data:`,
        data
      );
    }
  },
  /**
   * 用户登录打点方法
   */
  myLoginLog(data) {
    if (this.tlog) {
      this.tlog.profile({ user_unique_id: data });
      console.log("登录打点");
    }
  },
};

export default tLog;
