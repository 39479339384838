import Vue from "vue";
import store from "./store";
import router from "./router";
import App from "./App.vue";

// 登录、权限校验及获取数据相关方法
import { checkLogin } from "@/utils/business/login/login";
import { idaasAuth, hasAuth } from "@/utils/business/auth/authIdass";

// microApp相关方法
import { globalFunctions, micro } from "@/utils/microApp/micro";

// import "normalize.css"; // 引用基础兼容性css，normalize.css；文档：https://github.com/necolas/normalize.css
import "@/assets/css/reset.scss";
import "./assets/css/style/index.scss"; // 引用全局样式类

// 按钮权限
import btnAuth from "@/utils/business/auth/btnAuth.js"
// 脱敏权限
import desensitization from "@/utils/business/auth/desensitization"
// 如下为自定义工具函数
import { initAsyn_promise } from "@/utils/loadJs";
import { urlToJson } from "@/utils/common/url_query";
import { showTip } from "@/utils";
import { appId, product } from "@/config/base.config";
import tLog from "@/utils/tlog/tlog";

// element库:css
import "@xtdev/xt-element-ui/lib/theme-chalk/index.css";
// # 引入avue:css
import "@xtdev/xt-avue/lib/index.css";

const env = process.env.VUE_APP_ENV; // 当前构建的环境变量(development、test、production)
const debug = urlToJson().paramJson.openDebug ? true : false; // 线上url后缀带有openDebug=1时，也会开启console看板和性能调试
const productionTip = env === "production" && !debug ? false : true; // 生产环境下且url上不带有openDebug=1参数时，关闭vue性能提示
  
// 为了在vue实例化之前先加载某些依赖，第一个参数为[]时，直接执行回掉；否则，先加载js，加载完成后执行回掉；
const sourceArr = [];
Vue.use(btnAuth)
Vue.use(desensitization)
Promise.all([
  initAsyn_promise(sourceArr), // 初始化vue之前进行，某些资源的预加载
  checkLogin(), // 校验登录
]).then(async () => {
  micro.start();
  // 注册全局方法
  micro.registerStart(globalFunctions);
  
  // 线上环境，注册火山引擎
  tLog.init(appId);
  tLog.register(product,localStorage.getItem('guid'));
  
  // 获取用户权限信息
  const userAuthInfo = await idaasAuth();
  store.commit("SET_USERAUTHINFO", userAuthInfo.data || {});

  if (!hasAuth(userAuthInfo)) {
    showTip(`暂无权限！请联系管理员开启访问权限！`, `warning`);
    return;
  }

  Vue.config.productionTip = productionTip;
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
