const channelLine = [
  {
    index: "2",
    title: "网点管理",
    icon: "el-icon-s-shop",
    permissionValue: "channelLinePointManger",
    children: [
      {
        index: "2-1",
        name: "pointManage",
        title: "网点管理",
        permissionValue: "channelLinePoint",
      },
      {
        index: "2-2",
        name: "highQualityPointManage",
        title: "优质网点管理",
        permissionValue: "channelLineHighQualityPointManage",
      },
      {
        index: "2-3",
        name: "pointLevelDeploy",
        title: "网点等级划分",
        permissionValue: "channelLinePointLevel",
      },
      {
        index: "2-4",
        name: "changeCollectionWechat",
        title: "更换收款微信",
        permissionValue: "channelLineChangeCollectionWechat",
      },
    ],
  },
  {
    index: "10",
    title: "经销商管理",
    icon: "el-icon-user-solid",
    permissionValue: "channelLineSellerManger",
    children: [
      {
        index: "10-1",
        name: "agentManageNew",
        title: "经销商管理",
        permissionValue: "channelLineAgenNew",
      },
      {
        index: "10-2",
        name: "hongShangAgentManage",
        title: "红上经销商管理",
        permissionValue: "channelHongShangAgentManage",
      },
      // {
      //   index: "10-2",
      //   name: "businessArea",
      //   title: "业务覆盖概况",
      // },
    ],
  },
  {
    index: "11",
    title: "签约管理",
    icon: "el-icon-s-order",
    permissionValue: "channelLineSignManger",
    children: [
      {
        index: "11-1",
        name: "departmentAttributeConfig",
        title: "部门属性配置",
        permissionValue: "channelLineDeptConfig",
      },
      {
        index: "11-2",
        name: "contractList",
        title: "合同管理",
        permissionValue: "channelLineContractList",
      },
      {
        index: "11-3",
        name: "terminationContract",
        title: "解约管理",
        permissionValue: "channelLineContractJy",
      },
      {
        index: "11-4",
        name: "preTerminationContract",
        title: "预解约管理",
        permissionValue: "channelLinePreTermination",
      },
    ],
  },
  // {
  //   index: "3",
  //   title: "目标管理",
  //   icon: "el-icon-s-help",
  //   permissionValue: "channelLineTargetManger",
  //   children: [
  //     {
  //       index: "3-1",
  //       name: "indexLibrary",
  //       title: "指标库",
  //       permissionValue: "channelLineIndexLibrary",
  //     },
  //     {
  //       index: "3-3",
  //       name: "planObjective",
  //       title: "计划目标",
  //       permissionValue: "channelLinePlanObjective",
  //     },
  //   ],
  // },
  // 暂时下线，不删除代码
  // {
  //   index: "31",
  //   title: "演示工具管理",
  //   icon: "el-icon-s-claim",
  //   permissionValue: "channelLineShowToolManger",
  //   children: [
  //     {
  //       index: "31-1",
  //       name: "buyWineDemo",
  //       title: "展厅购酒管理",
  //       permissionValue: "channelLineBuyWineDemo",
  //     },
  //     {
  //       index: "31-3",
  //       name: "banquetDemo",
  //       title: "宴席演示管理",
  //       permissionValue: "channelLineBanquetDemo",
  //     },
  //     {
  //       index: "31-31",
  //       name: "forumDemo",
  //       title: "论坛演示管理",
  //       permissionValue: "channelLineForumDemo",
  //     },
  //   ],
  // },
  {
    index: "4",
    title: "物料管理",
    icon: "el-icon-s-home",
    permissionValue: "channelLineMaterialManger",
    children: [
      {
        index: "4-1",
        name: "materialsDeploy",
        title: "物料配置",
        permissionValue: "channelLineDeploy",
      },
      {
        index: "4-3",
        name: "materialsApplyList",
        title: "物料申请列表",
        permissionValue: "channelLineApplyList",
      },
      {
        index: "4-32",
        name: "materialsApplyDetail",
        title: "物料申请明细",
        permissionValue: "channelLineApplyDetail",
      },
    ],
  },
  {
    index: "5",
    title: "基础配置",
    icon: "el-icon-s-claim",
    permissionValue: "channelLineBaseConfig",
    children: [
      // {
      //   index: "5-1",
      //   name: "basicConfig",
      //   title: "区域配置",
      // },
      // {
      //   index: "5-3",
      //   name: "sellConfig",
      //   title: "经销商区域配置",
      // },
      {
        index: "5-32",
        name: "productConfig",
        permissionValue: "channelLineProductConfig",
        title: "产品配置",
      },
      // {
      //   index: "5-4",
      //   name: "roleRelation",
      //   title: "业务员配置",
      // },
      {
        index: "5-5",
        name: "baseDeploy",
        title: "网点基本信息配置",
        permissionValue: "channelLineBaseDeploy",
      },
    ],
  },
  {
    index: "6",
    title: "消息中心",
    icon: "el-icon-s-custom",
    permissionValue: "messageCenter",
    children: [
      {
        index: "6-1",
        name: "createMessage",
        title: "创建消息",
        permissionValue: "createMessage",
      },
      {
        index: "6-2",
        name: "messageGroup",
        title: "消息分组",
        permissionValue: "messageGroup",
      },
    ],
  },
  {
    index: "7",
    title: "用户管理",
    icon: "el-icon-s-check",
    permissionValue: "channelLineUserManger",
    children: [
      {
        index: "7-1",
        name: "userManage",
        title: "用户管理",
        permissionValue: "channelLineUse",
      },
      {
        index: "7-2",
        name: "userSearch",
        title: "用户搜索",
        permissionValue: "channelLineUseSearch",
      },
      {
        index: "7-3",
        name: "userList",
        title: "用户列表",
        permissionValue: "channelLineUserList",
      },
      {
        index: "7-4",
        name: "userGrouping",
        title: "用户分组",
        permissionValue: "channelLineUserGrouping",
      },
    ],
  },
  {
    index: "8",
    title: "网格管理",
    icon: "el-icon-s-grid",
    permissionValue: "channelLineInternetManger",
    children: [
      {
        index: "8-1",
        name: "netManageDetail",
        title: "网格管理",
        permissionValue: "channelLineNetManageDetail",
      },
      {
        index: "8-2",
        name: "netManageZs",
        title: "招商管理",
        permissionValue: "channelLineNetManageZs",
      },
      {
        index: "8-3",
        name: "quotaManage",
        title: "配额管理",
        permissionValue: "channelLineQuotaManage",
      },
    ],
  },
  {
    index: "9",
    title: "招商和渠道活动",
    icon: "el-icon-s-management",
    permissionValue: "channelLineActivity",
    children: [
      {
        index: "9-1",
        name: "investmentActivity",
        title: "招商活动管理",
        permissionValue: "channelLineInvestment",
      },
      {
        index: "9-2",
        name: "applyMessage",
        title: "报名信息管理",
        permissionValue: "channelLineApplyMessage",
      },
      {
        index: "9-3",
        name: "applyManage",
        title: "品鉴管理",
        permissionValue: "channelLineApplyManage",
      },
      {
        index: "9-4",
        name: "wineReplenishment",
        title: "品鉴酒补发记录",
        permissionValue: "channelLineReplenishment",
      },
      // {
      //   index: "9-5",
      //   name: "smallware",
      //   title: "小品会费用",
      // },
      // {
      //   index: "9-6",
      //   name: "sodality",
      //   title: "联谊会费用",
      // },
    ],
  },
  {
    index: "12",
    title: "拜访管理",
    icon: "el-icon-platform-eleme",
    permissionValue: "channelLineBfManger",
    children: [
      {
        index: "12-1",
        name: "visitTemplate",
        title: "拜访模板",
        permissionValue: "channelLineBfModel",
      },
      {
        index: "12-2",
        name: "visitList",
        title: "拜访记录",
        permissionValue: "visitList",
      },
    ],
  },
  {
    index: "13",
    title: "潭酒商家管理",
    icon: "el-icon-s-flag",
    permissionValue: "channelLineVhomeManage",
    children: [
      {
        index: "13-1",
        name: "vhomePolicyManage",
        title: "内容精选管理",
        permissionValue: "channelLineVhomePolicyManage",
      },
    ],
  },
  {
    index: "14",
    title: "企业信息管理",
    icon: "el-icon-office-building",
    permissionValue: "channelLineEnterpriseManage",
    children: [
      {
        index: "14-1",
        name: "enterpriseManage",
        title: "企业管理",
        permissionValue: "channelLineEnterpriseManageList",
      },
    ],
  },
  {
    index: "15",
    title: "物资管理",
    icon: "el-icon-s-platform",
    permissionValue: "materialManage",
    children: [
      {
        index: "15-1",
        name: "staffMaterialManage",
        title: "员工物资管理",
        permissionValue: "staffMaterialManage",
      },
    ],
  },
  {
    index: "16",
    title: "线索管理",
    icon: "el-icon-s-help",
    permissionValue: "channelLineClueManage",
    children: [
      {
        index: "16-1",
        name: "restaurantList",
        title: "网点线索管理",
        permissionValue: "channelLineRestaurantClueManageList",
      },
      {
        index: "16-2",
        name: "dealerClueList",
        title: "经销商线索管理",
        permissionValue: "channelLineDealerClueManageList",
      },
    ],
  },
  {
    index: "17",
    title: "费控",
    icon: "el-icon-s-help",
    permissionValue: "channelLineFeikong",
    children: [
      {
        index: "17-1",
        name: "feikongActManage",
        title: "大区活动管理",
        permissionValue: "channelLineFeikongManage",
      },
      {
        index: "17-2",
        name: "feikongActConfig",
        title: "大区活动配置",
        permissionValue: "channelLineFeikongPeizhi",
      },
      // {
      //   index: "17-3",
      //   // name: "channelLineFeikongSource",
      //   title: "资源管理",
      //   permissionValue: "channelLineFeikongSource",
      //   children: [
      //     {
      //       index: "17-3-1",
      //       name: "feikongBigArea",
      //       title: "大区",
      //       permissionValue: "channelLineFeikongSourceDaqu",
      //     },
      //     {
      //       index: "17-3-2",
      //       name: "feikongProvince",
      //       title: "省",
      //       permissionValue: "channelLineFeikongSourceProvince",
      //     },
      //     {
      //       index: "17-3-3",
      //       name: "feikongShang",
      //       title: "商",
      //       permissionValue: "channelLineFeikongSourceShang",
      //     },
      //     {
      //       index: "17-3-4",
      //       name: "feikongStore",
      //       title: "烟酒餐饮店",
      //       permissionValue: "channelLineFeikongSourceStore",
      //     },
      //   ],
      // },
      {
        index: "18-3",
        // name: "sourceWaterBig",
        title: "资源流水",
        permissionValue: "channelLineFeikongWater",
        children: [
          {
            index: "18-3-1",
            name: "sourceWaterBig",
            title: "大区",
            permissionValue: "channelLineFeikongWaterBigArea",
          },
          {
            index: "18-3-2",
            name: "sourceWaterPro",
            title: "省",
            permissionValue: "channelLineFeikongWaterProvince",
          },
          {
            index: "18-3-3",
            name: "sourceWaterShang",
            title: "商",
            permissionValue: "channelLineFeikongWaterShang",
          },
          {
            index: "18-3-4",
            name: "sourceWaterStore",
            title: "烟酒餐饮店",
            permissionValue: "channelLineFeikongWaterStore",
          },
        ],
      },
      {
        index: "17-4",
        // name: "sourceWaterBig",
        title: "费控配置",
        permissionValue: "channelLineFeikongConfig",
        children: [
          {
            index: "17-4-1",
            name: "quarterProjectUpperConfig",
            title: "立项额度上限配置",
            permissionValue: "channelLineFeikongLimitUpperConfig",
          },
          {
            index: "17-4-2",
            name: "importantPoint",
            title: "重点网点/商配置",
            permissionValue: "channelLineFeikongImportantPointConfig",
          },
        ],
      },
      {
        index: "17-5",
        name: "feeGeneration",
        title: "费用生成",
        permissionValue: "channelLineFeikongFeeGeneration",
      },
    ],
  },
  {
    index: "19",
    title: "绩效管理",
    icon: "el-icon-s-check",
    permissionValue: "channelLineKpiManger",
    children: [
      {
        index: "19-1",
        name: "kpiDataSum",
        title: "绩效数据汇总",
        permissionValue: "channelLinekpiDataSum",
        children: [
          {
            index: "19-1-1",
            title: "城市经理绩效汇总",
            permissionValue: "channelLinekpiDataSumCityManager",
            name: "cityManagerDataSum",
          },
          {
            index: "19-1-2",
            title: "潭小二绩效汇总",
            permissionValue: "channelLinekpiDataSumTanxer",
            name: "tanxerDataSum",
          },
        ],
      },
      {
        index: "19-2",
        name: "kpiTaskDetail",
        title: "考核任务明细",
        permissionValue: "channelLinekpiTaskDetail",
        children: [
          {
            index: "19-2-1",
            title: "触发红线",
            permissionValue: "channelLinekpiDataSumTouchRedLine",
            name: "touchRedLine",
          },
          {
            index: "19-2-2",
            title: "进货明细",
            permissionValue: "channelLinekpiDataSumPurchaseDetail",
            name: "purchaseDetail",
          },
          {
            index: "19-2-3",
            title: "开瓶明细",
            permissionValue: "channelLinekpiDataSumCrackDetail",
            name: "crackDetail",
          },
        ],
      },
      {
        index: "19-3",
        name: "policyUpdateManage",
        title: "绩效政策更新管理",
        permissionValue: "channelLinePolicyUpdateManage",
      },
    ],
  },
  {
    index: "20",
    title: "盖回收",
    icon: "el-icon-upload",
    permissionValue: "channelLineRecyclingCup",
    children: [
      {
        index: "20-1",
        title: "下载回收盖码数据",
        permissionValue: "channelLineRecyclingcupdownLoadData",
        name: "downRecyclingCup",
      },
      {
        index: "20-2",
        title: "核验上传结果",
        permissionValue: "channelLineRecyclingcupLoadData",
        name: "upRecyclingCup",
      },
    ],
  },
  {
    index: "21",
    title: "推文管理",
    icon: "el-icon-upload",
    permissionValue: "channelLineTArticleManage",
    children: [
      {
        index: "21-1",
        title: "发布内容",
        permissionValue: "channelLineTArticlePublish",
        name: "tArticlePublish",
      },
      {
        index: "21-2",
        title: "发布记录",
        permissionValue: "channelLineTArticleLog",
        name: "tArticleLog",
      },
      {
        index: "21-3",
        title: "效果分析",
        permissionValue: "channelLineTArticleAnalysis",
        name: "tArticleAnalysis",
      },
      {
        index: "21-4",
        title: "LBS推荐企微",
        permissionValue: "channelLineTArticleLBS",
        name: "tArticleSet",
      },
    ],
  },
];

export { channelLine };
